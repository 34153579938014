import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';

interface IProps {
     options: Array<{ value: string | number, label: string, id: string }>;
     onChange: (value: string) => void;
     selectedValue: { value: string | number, label: string, id: string };
     width: number;
     title: string;
     showHelpInfo?: boolean;
     helpText?: string;
     invalidState?: boolean;
     invalidText?: string;
     disabled?:boolean;
}
const DropdownWidget = (props: IProps) => {

     const { t } = useTranslation();

     const renderTooltipQuestionTwo = (props) => (
          <Tooltip id="question-tooltip" {...props} style={{ position: "absolute", left: "-50%" }}>
               {props.text}
          </Tooltip>
     );

     const customStyles = {
          option: (provided, state) => ({
               ...provided,
               color: '#1F2747',
               maxHeight: '176px',
               height: '32px',
               padding: '0px 16px',
               background: state.isSelected ? '#F3F4F5' : state.isFocused ? '#F3F4F5' : undefined,
               display: 'flex',
               alignItems: 'center',
               "&:active": {
                    background: '#F3F4F5',
               }

          }),
          control: (base, state) => ({
               // none of react-select's styles are passed to <Control />
               ...base,
               width: "528px",
               // height: '36px',
               color: '#1F2747',
               background: ' #FFFFFF',
               boxShadow: 'inset 0px 2px 4px rgba(31, 39, 71, 0.1)',
               borderRadius: '8px',
               transition: 'none',
               display: 'flex',
               border: state.menuIsOpen ? '1px solid #6C89E8' : '1px solid #C6C8CE',
               '&:hover': {
                    border: '1px solid #4B5168',
               }
          }),
          valueContainer: base => ({
               ...base,
               display: 'flex',
               padding: '0px 16px',
          }),
     }

     const handleTagChange = (newValue) => {
          props.onChange(newValue);
     }

     // const filteredOptions = props.options.filter(option => option.value !== props.selectedValue.value);

     return (

          <div className='userManagementTextWidget' style={{ width: props.width }}>
               <div className='userManagementTextWidgetHeader'>
                    <div className='userManagementTextWidgetHeaderOne'>
                         <div className='problemCanvasTitleOne'>
                              {props.title}
                         </div>
                         {props.showHelpInfo && <OverlayTrigger placement='top' overlay={renderTooltipQuestionTwo({ text: props.helpText })} >
                              <div>
                                   <FontAwesomeIcon icon={faCircleQuestion} className='problemCanvasQuestionIconOne' />
                              </div>
                         </OverlayTrigger>}

                    </div>
               </div>
               <CreatableSelect
                    styles={customStyles}
                    onChange={handleTagChange}
                    defaultValue={props.selectedValue}
                    options={props.options}
                    menuPosition="fixed"
                    components={{ IndicatorSeparator: () => null }}
                    inputId="dropdown"
                    isDisabled={props.disabled}
               />
               {props.invalidState && <div className="invalidInputOne"> {props.invalidText}</div>}
          </div>

     );

}

export default DropdownWidget;