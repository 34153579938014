import { useCallback, useEffect, useRef, useState } from 'react';
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import RGL from "react-grid-layout";
import { GetRandomColor } from './GetRandomColor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { ISapphirePortalComm } from '../comm/WebSocket';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './css/PanelView.scss';

export interface ConnectionMapping {
    connectionID: string;
    guestName: string;
    mainConnectionID: string;
}

export interface LayoutItem {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number;
    static?: boolean;
    isResizable: boolean;
    isDraggable: boolean;
}

interface IProps {
    isShow: boolean;
    layout: Array<LayoutItem>;
    setLayout: (layout: LayoutItem[]) => void;
    localLayout: Array<LayoutItem>;
    setLocalLayout: (layout: LayoutItem[]) => void;
    currentGuests: object;
    guestName: string;
    connectionMapping: Array<ConnectionMapping>;
    setLayoutData: (connectionMapping: ConnectionMapping[], layout: LayoutItem[]) => void;
    setLayoutAndSendToAllUponChange: (connectionMapping: ConnectionMapping[], layout: LayoutItem[], layoutType:string) => void;
    meetingRoomID: string;
    doubleClickForSpotlight: boolean;
    setSelectedValue: (selectedValue: string) => void;
    sapphirePortalComm: ISapphirePortalComm;
    selectedValue: string;
    isConferanceActive: boolean;
    displayBar: boolean;
    sourcesBarHidden: boolean;
    currentSources: Array<any>;
    isConferenceActive: boolean;
}

// to get the first letters of the name
export const getAbbreviation = (name: string) => {
    if (!name) return ''; // Return an empty string if the name is not provided

    const words = name.split(' '); // Split the name into an array of words
    const initials = words.map(word => word.charAt(0)); // Get the first letter of each word
    const abbreviation = initials.join('').toUpperCase(); // Combine, capitalize, and return the result

    return abbreviation;
};

/**
 * 
 * This is where screens shared by all the users are displayed
 * 
 * @param props 
 * @returns 
 */
export default function ScreenSharePanel(props: IProps) {

    const { t } = useTranslation();

    /**
     * getWindowWidth function, when called, calculates and returns the maximum width of the window by considering
     * the inner width of the window and the offset widths of the <body> and <html> elements.
     * The useCallback hook is used to memoize the function for performance optimization.
     * 
     * window.innerWidth: The inner width of the browser window.
     * document.body.offsetWidth: The offset width of the <body> element.
     * document.documentElement.offsetWidth: The offset width of the root <html> element.
     */
    const getWindowWidth = useCallback(() => {
        return Math.max(window.innerWidth, document.body.offsetWidth, document.documentElement.offsetWidth);
    }, []);

    /**
     * getWindowHeight function, when called, calculates and returns the maximum height of the window by considering
     * the inner height of the window and the offset heights of the <body> and <html> elements.
     * The useCallback hook is used to memoize the function for performance optimization.
     * 
     * window.innerHeight: The inner height of the browser window.
     * document.body.offsetHeight: The offset height of the <body> element.
     * document.documentElement.offsetHeight: The offset height of the root <html> element.
     */
    const getWindowHeight = useCallback(() => {
        return Math.max(window.innerHeight, document.body.offsetHeight, document.documentElement.offsetHeight);
    }, []);

    const [windowWidth, setWindowWidth] = useState(getWindowWidth());
    const [windowHeight, setWindowHeight] = useState(getWindowHeight());

    const [localLayout, setLocalLayout] = useState<LayoutItem[]>([]);

    const getRandomColor = GetRandomColor();

    const [screens, setScreens] = useState([]);
    let tmpScreens = [];

    // it handles changing the spotlight when user double clicks on some participants
    const handleDoubleClick = (connectionID) => {
        // double click disabled
        if (props.selectedValue === 'equal') {
            return 0;
        }
        var secondaryIndex = 0;
        var mainLayoutUpdate = [];
        props.layout.forEach((frame, index) => {
            if (frame.i === connectionID) {
                mainLayoutUpdate.push({ i: frame.i, x: 0, y: 0, w: props.layout.length > 1 ? 40 : 50, h: 5, static: true, isDraggable: false, isResizable: false })
            }
            else {
                mainLayoutUpdate.push(
                    { i: frame.i, x: 40, y: secondaryIndex++, w: 10, h: 1, static: true, isDraggable: false, isResizable: false }
                );
            }
        })
        setLocalLayout(mainLayoutUpdate);
        const connectionMapping = props.connectionMapping.map(connection => {
            return {
                ...connection,
                mainConnectionID: connectionID
            };
        });
        props.setLayoutAndSendToAllUponChange(connectionMapping, mainLayoutUpdate, props.selectedValue);
    };

    // on dragging the participants window
    const onDragStop = (newLayout) => {
        console.log('Updated layout:', newLayout);
        if (JSON.stringify(newLayout) !== JSON.stringify(localLayout)) {
            setLocalLayout(newLayout);
            props.setLayoutAndSendToAllUponChange(props.connectionMapping, newLayout, props.selectedValue);
        }
    };

    // on resizing the participants window
    const onResizeStop = (newLayout) => {
        console.log('Updated layout:', newLayout);
        if (JSON.stringify(newLayout) !== JSON.stringify(localLayout)) {
            setLocalLayout(newLayout);
            props.setLayoutAndSendToAllUponChange(props.connectionMapping, newLayout, props.selectedValue);
        }
    };

    /**
     * function  handles the "resize" event on the window, updating the state with the current window width and height.
     */
    const onWindowResize = useCallback(() => {
        setWindowWidth(getWindowWidth());
        setWindowHeight(getWindowHeight());
    }, []);

    /**
     * this code sets up an event listener to track window resize events, and it will
     * invoke the onWindowResize function when the window is resized. The listener is
     * added when the component mounts and removed when the component is unmounted.
     */
    useEffect(() => {
        //get the layout if you join in the middle of meeting.
        props.sapphirePortalComm.getLayoutFromDatabase();
        window.addEventListener("resize", onWindowResize);

        return () => {
            window.removeEventListener("resize", onWindowResize);
        }
    }, []);

    // sets the local layout when props.layout changes
    useEffect(() => {
        const changeLayoutStaticTrue = props.layout.map((layout) => ({ ...layout, static: true, isResizable: false, isDraggable: false }))
        setLocalLayout(changeLayoutStaticTrue);
    }, [props.layout])

    useEffect(() => {
        const changeLayoutStaticTrue = props.localLayout.map((layout) => ({ ...layout, static: true, isResizable: false, isDraggable: false }))
        setLocalLayout(changeLayoutStaticTrue);
    }, [props.localLayout])

    useEffect(() => {

        if (props.connectionMapping && props.layout.length !== 0) {

            props.connectionMapping.forEach((currentConnection, index, connectionMapping) => {

                // we are limiting to display the number of participants to 6
                // if (index > 5)
                //     return;

                tmpScreens.push(
                    (<div key={currentConnection.connectionID} style={{ backgroundColor: getRandomColor(currentConnection.connectionID) }} className="screenDiv" onDoubleClick={() => {
                        handleDoubleClick(currentConnection.connectionID);
                    }} >
                        <div className="profile" >
                            {/* <div className="circle">
                                {currentConnection.guestName[0]}
                            </div> */}
                            <div id={"guestNameDiv" + currentConnection.connectionID} className="guestNameDiv" style={{ display: currentConnection.guestName ? "" : "none" }}>
                                {currentConnection.guestName}
                            </div>
                        </div>
                        {/** when index is less than 5 or there are total 6 users sharing their screen */}
                        {/* {(index < 5 || props.connectionMapping.length === 6) && <div className={`abbreName ${props.selectedValue === 'spotlight' && index !== 0 && 'abbreNameSmall'}`} >
                            {getAbbreviation(currentConnection.guestName)}
                        </div>}
                        {(index === 5 && props.connectionMapping.length > 6) &&
                            <div className='abbreDiv'>
                                <div className={`abbreName ${props.selectedValue === 'spotlight' && 'abbreNameSmall'}`} >
                                    {getAbbreviation(currentConnection.guestName)}
                                </div>
                                <div className="abbreDivTotal">
                                    <FontAwesomeIcon icon={faPlus} />
                                    {`${props.connectionMapping.length - 6}`}
                                </div>
                            </div>
                        } */}
                        {/** when index is less than 5 or there are total 6 users sharing their screen */}
                        {/* <div className={`abbreName ${props.selectedValue === 'spotlight' && index !== 0 && 'abbreNameSmall'}`} >
                            {getAbbreviation(currentConnection.guestName)}
                        </div> */}
                        <div className={'abbreName abbreNameSmall'} >
                            {getAbbreviation(currentConnection.guestName)}
                        </div>
                    </div>)
                );

            });
            setScreens(tmpScreens);
        }
    }, [props.connectionMapping, props.selectedValue]);

    // if spotlight, then divide the whole layout into 5 rows, else 1 or 2 depending on the localLayout length
    const dividefactor = props.selectedValue === "spotlight" ? 5 : localLayout.length > 2 ? 2 : 1;
    const calculated = props.currentSources.length !== 0 ? (window.innerHeight - 268) / dividefactor : (window.innerHeight - 212) / dividefactor;
    const calculatedWidth = props.isConferanceActive ? windowWidth - 468 : windowWidth;

    return (
        <div className={!props.connectionMapping ? 'hidden' : props.connectionMapping.length === 0 ? 'presentPanel presentPanel3' : 'presentPanel'} style={{ height: `${props.currentSources.length !== 0 ? 'calc(100vh - 168px)' : 'calc(100vh - 112px)'}` }}>
            {props.connectionMapping.length === 0 &&
                <div className='all-content'>
                    <div className="shared-content">
                        <div className='shared-content-label'>{t('PortalApp.ShareHead')}</div>
                        <p>{t('PortalApp.ShareDes')}</p>
                    </div>
                    <div className='sharedImagesDiv'>
                        <img src="./assets/multiScreen.png" />
                    </div>

                </div>
            }
            {props.connectionMapping.length !== 0 && <RGL
                width={calculatedWidth}
                className='layout'
                margin={[0, 0]}
                layout={localLayout}
                // onDragStop={onDragStop}
                // onResizeStop={onResizeStop}
                cols={50} // number of columns in the layout
                rowHeight={calculated}
                containerPadding={[50, 50]}
            >
                {screens}
            </RGL>}
        </div>
    );

}
