import React, { useState, useEffect, useRef } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import CustomSearch from '../common/CustomSearch';
import './resourcesBar.scss';
import { useTranslation } from 'react-i18next';
import ResourceCard from '../common/ResourceCard';
import { isMappingGroupMultiWindow } from '../common/ResourceCard';
import { getMultiWindowGroupName } from '../common/ResourceCard';
import { Modal } from 'react-bootstrap';
import { faXmark, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import Button from '../common/Button';
interface IProps {
  sourcesBarHidden: boolean;
  setSourcesBarHidden: (sourcesBarHidden: boolean) => void;
  // artistic, trends, and decisions
  currentSources: Array<any>;
  guestName: string;
  pairingCode: string;
  sendHLSLink: (pairingCode: string, currentGuest: string, sourceName: string, link: string) => void;
  searchValue: string;
  setSearchValue: Function;
}

export default function ResourcesBar(props: IProps) {

  const { t } = useTranslation();

  // const [selectedSourceIndex, setSelectedSourceIndex] = useState<number | null>(null);
  // state for the sharing modal when no HLS stream is currently being shared
  const [shareResource, setShowResource] = useState(false);
  // state for unsharing modal when user clicks on the same card that is being currenlty shared
  const [unshareResource, setUnshareResource] = useState(false);
  // state for unsharing when clicks on the card that is not being shared
  const [unshareDiff, setUnshareDiff] = useState(false);
  // stores the index of HLS being shared
  const shareIndex = useRef(-1);

  const closeShareResourceDialog = () => {
    shareIndex.current = -1;
    setShowResource(false);
  }

  const closeUnshareResourceDialog = () => {
    setUnshareResource(false);
  }
  const closeUnshareResoruceDiff = () => {
    setUnshareDiff(false);
  }

  const handleSourceClick = (index: number) => {
    var hlsLink = null;
    var resourceName = null;
    for (const element of filteredResources[index].streams) {
      if (element.streamData.type === "HLS") {
        hlsLink = element.streamData.url;
        resourceName = element.name;
        break;
      }
    }
    if (hlsLink && resourceName) {
      props.sendHLSLink(props.pairingCode, props.guestName, resourceName, hlsLink);
    } else {
      console.log("No HLS link found.");
    }
  };

  const hanldeSourceCardClick = (index: number) => {
    if (shareIndex.current === -1) {
      shareIndex.current = index;
      setShowResource(true);
      return;
    }
    if (shareIndex.current === index) {
      setUnshareResource(true);
      return;
    }
    if (shareIndex.current !== index) {
      setUnshareDiff(true);
      return;
    }
  }

  const filteredSourcesForHLS = props.currentSources.filter((source) => {
    const hasHLSStream = source.streams.some(stream => 
      stream.streamData.type === "HLS"
    );
    return !!hasHLSStream;
  });

  // filter based on search value
  const filteredResources = props.searchValue === "" ? filteredSourcesForHLS : props.currentSources.filter((source) => {
      if (source.insets.length === 0) return false;

      const hasHLSStream = source.streams.some(stream => 
        stream.streamData.type === "HLS"
      );

      if (!hasHLSStream) return false;

      const isMultiwindow = isMappingGroupMultiWindow(source.name);
      const title = isMultiwindow ? getMultiWindowGroupName(source.name) : source.insets[0].name;
      return title.toLowerCase().includes(props.searchValue.toLowerCase())
  });
  

  return (
    <>
      <div className="resourceBar">
        <div className="resourceTopBar">
          <CustomSearch getSearchValue={(value) => props.setSearchValue(value)} setSearchValue={(value) => props.setSearchValue(value)} />
          <div className='barArrowDiv' onClick={() => { props.setSourcesBarHidden(!props.sourcesBarHidden) }}>
            {!props.sourcesBarHidden && <FontAwesomeIcon icon={faChevronDown as IconProp} className='iconArrow' />}
            {props.sourcesBarHidden && <FontAwesomeIcon icon={faChevronUp as IconProp} className='iconArrow' />}
          </div>
        </div>
        <div className='resourceBarContent' hidden={props.sourcesBarHidden}>
          {filteredResources.length === 0 ?
            <div className='emptyBar'>
              {t('PortalApp.NoResourceAvailable')}
            </div>
            : <div className="source-list">
              {
                filteredResources.map((source, index) => (

                  <ResourceCard
                    mapperName={source.name}
                    insets={source.insets}
                    onClick={() => hanldeSourceCardClick(index)}
                  />
                ))
              }
            </div>}
        </div>
      </div>
      {/**Modal opens before sharing new resource*/}
      <Modal
        show={shareResource}
        onHide={() => closeShareResourceDialog()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className='modal-header-title' style={{ marginBottom: "0px" }}>
          <Modal.Header>
            <div>
              <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: " FFA200", marginRight: "8px" }} />
              {t('PortalApp.CorporateResourceSecurity')}
            </div>
            <div className='modal-close-btn'>
              <FontAwesomeIcon icon={faXmark as IconProp} onClick={() => closeShareResourceDialog()} className="custom-close-btn" style={{ width: "100%" }} />
            </div>
          </Modal.Header>
        </div>

        <Modal.Body>
          {t('PortalApp.CorporateResourceSecurityDes')}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeShareResourceDialog()}>{t('PortalApp.Cancel')}</Button>
          <Button variant="primary" onClick={() => {
            handleSourceClick(shareIndex.current);
            setShowResource(false);
          }}>
            {t("PortalApp.ShareContent")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={unshareResource}
        onHide={() => closeUnshareResourceDialog()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className='modal-header-title' style={{ marginBottom: "0px" }}>
          <Modal.Header>
            <div>
              <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: " FFA200", marginRight: "8px" }} />
              {t('PortalApp.EndResourceSharing')}
            </div>
            <div className='modal-close-btn'>
              <FontAwesomeIcon icon={faXmark as IconProp} onClick={() => closeUnshareResourceDialog()} className="custom-close-btn" style={{ width: "100%" }} />
            </div>
          </Modal.Header>
        </div>

        <Modal.Body>
          {t('PortalApp.EndResourceSharingDes')}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeUnshareResourceDialog()}>{t('PortalApp.Cancel')}</Button>
          <Button variant="primary" onClick={() => {
            handleSourceClick(shareIndex.current);
            setUnshareResource(false);
            shareIndex.current = -1;
          }}>
            {t("PortalApp.UnshareContent")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={unshareDiff}
        onHide={() => closeUnshareResoruceDiff()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className='modal-header-title' style={{ marginBottom: "0px" }}>
          <Modal.Header>
            <div>
              <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: " FFA200", marginRight: "8px" }} />
              {t('PortalApp.Information')}
            </div>
            <div className='modal-close-btn'>
              <FontAwesomeIcon icon={faXmark as IconProp} onClick={() => closeUnshareResoruceDiff()} className="custom-close-btn" style={{ width: "100%" }} />
            </div>
          </Modal.Header>
        </div>

        <Modal.Body>
          {t('PortalApp.InformationDes')}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeUnshareResoruceDiff()}>{t('PortalApp.Cancel')}</Button>
          <Button variant="primary" onClick={() => {
            handleSourceClick(shareIndex.current);
            setUnshareDiff(false);
            shareIndex.current = -1;
          }}>
            {t("PortalApp.EndSharing")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
