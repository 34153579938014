import { useTranslation } from "react-i18next";
import './VideoConferencing.scss';
import TextWidget from "../common/TexWidget";
import SelectConferenceType from "./SelectConferenceType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faArrowUpFromSquare } from "@fortawesome/pro-solid-svg-icons";
import Button from "../common/Button";
import React, { useState } from 'react';
interface IProps {
    meetingRoomName: string;
    guestName: string;
    setGuestName: Function;
    uCast: boolean;
    setUCast: Function;
    handleLeaveSpace: Function;
    joinSpace: Function;
    conferenceType: number;
    setConferenceType: Function;
    disableConfrenceOptions: boolean;
    startCasting: (callback?: Function) => void;
}

const VideoConferencingPage = (props: IProps) => {

    const { t } = useTranslation();

    const [guestNameChangeInTheMeeting, setGuestNameChangeInTheMeeting] = useState("");
    const [validationBool, setValidationBool] = useState(false);

    return (

        <div className="videoConferencing">
            <div className="videoHeadDiv">
                <div className="videoHeading">
                    {t('PortalApp.VideoConferencing')}
                </div>
                <div className="videoDes">
                    {t('PortalApp.VideoConferencingDes')}
                </div>
            </div>

            <div className="fieldsDiv">
                <TextWidget
                    title={t('PortalApp.SpaceName')}
                    displayLength={false}
                    placeholder={t('PortalApp.MeetingLinkPlaceholder')}
                    keys="spaceName"
                    formValue={props.meetingRoomName}
                    invalidState={false}
                    invalidText={t('UserManagement.LDAP.Error.batchSizeForSyncError')}
                    width={509}
                    id="spaceName"
                    onChange={(e) => { props.setGuestName(e.target.value) }}
                    onBlur={(e) => { props.setGuestName(e.target.value) }}
                    showHelpInfo={false}
                    disabled={true}
                />
                <TextWidget
                    title={t('PortalApp.GuestName')}
                    displayLength={true}
                    placeholder={t('PortalApp.GuestNamePlaceholder')}
                    keys="guestName"
                    formValue={guestNameChangeInTheMeeting}
                    invalidState={guestNameChangeInTheMeeting === "" && validationBool}
                    invalidText={t('PortalApp.GuestNameValidation')}
                    width={509}
                    id="guestName"
                    onChange={(e) => { setGuestNameChangeInTheMeeting(e.target.value); setValidationBool(true); }}
                    onBlur={(e) => { setGuestNameChangeInTheMeeting(e.target.value); setValidationBool(true); }}
                    showHelpInfo={false}
                    maxCharAllowed={60}
                />
            </div>

            <div className="shareScreenVideo">

                <div className="selectDiv" style={{ backgroundColor: "#EDF0FC" }}>
                    <div className="selectDivHead">
                        <div className="selectDivHeadText">
                            {t('PortalApp.SecureLocalScreenShare')}
                        </div>
                        <div className="selectDivHeadDes">
                            {t('PortalApp.SecureLocalScreenShareDes')}
                        </div>
                    </div>
                    <div
                        key={`sam-app-select-uCast}`}
                        className={`appSelectItem${props.uCast ? " activeSelectItem" : ""}`}
                        onClick={() => props.setUCast(!props.uCast)}
                    >
                        <div className={`iamgeDiv${props.uCast ? " activeImageDiv" : ""}`}>
                            <FontAwesomeIcon icon={faArrowUpFromSquare} />
                        </div>
                        <div className="imageLabel">{t("PortalApp.ScreenShare")}</div>
                    </div>
                </div>

                <div className="selectDiv" style={{ backgroundColor: "#EAEBEE" }}>
                    <div className="selectDivHead">


                        <div className="selectDivHeadText">
                            {t('PortalApp.VideoConferencingOptional')}
                        </div>
                        <div className="selectDivHeadDes">
                            {t('PortalApp.VideoConferencingOptionalDes')}
                        </div>
                    </div>
                    <SelectConferenceType
                        conferenceType={props.conferenceType}
                        setConferenceType={props.setConferenceType}
                        disableConfrenceOptions={props.disableConfrenceOptions} />
                </div>
            </div>

            <div className="videoConferenceButtons">
                <Button variant="secondary" onClick={() => props.handleLeaveSpace()}>
                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
                    {t('PortalApp.Back')} </Button>
                <Button variant="primary" onClick={() => {
                    //Join guestName becomes uuid but the guestname that is shown to the user is guestNameChangeInTheMeeting
                    props.setGuestName(guestNameChangeInTheMeeting);
                    //Join Space logic handler
                    props.joinSpace();
                }} disabled={guestNameChangeInTheMeeting === ""}>
                    {t("PortalApp.JoinSpace")}
                    <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '8px' }} />
                </Button>
            </div >
        </div>
    );
}

export default VideoConferencingPage;