import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './ActionButton.scss';
interface IProps {
    icon: IconProp;
    text: string;
    onClick:Function;
}

const ActionButton = (props: IProps) => {

    return (
        <div className="actionButton" onClick={() => props.onClick()}>
            <div className="actionButtonImageDiv">
                <div className="actionButtonImage" >
                    <FontAwesomeIcon icon={props.icon} className="icon" />
                </div>
            </div>
            <div className="actionButtonTextDiv">
                {props.text}
            </div>

        </div>
    );
}

export default ActionButton;