import React, { useEffect, useState } from 'react';
import { Modal} from 'react-bootstrap';
import './css/Modal.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faDisplay } from '@fortawesome/pro-regular-svg-icons';

export default function MultiConnectionWarningModal(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className='modal-header-title'>
                <Modal.Header>
                    <div>
                        <FontAwesomeIcon icon={faDisplay as IconProp} style={{ marginRight: '8px' }} />
                        Someone is already presenting
                    </div>
                    <div className='modal-close-btn'>
                        <FontAwesomeIcon icon={faXmark as IconProp} onClick={props.handleClose} className="custom-close-btn" style={{ width: "100%" }} />
                    </div>
                </Modal.Header>
            </div>

            <Modal.Body>
                Someone is already casting inside of this meeting room, by doing this you will take over as the main presenter.
            </Modal.Body>

            <Modal.Footer>
                <button className="button-secondary" type='button' onClick={props.handleClose}>Cancel</button>
                <button
                    className="button-primary"
                    type='button'
                    name="showShareScreen"
                    onClick={() => {
                        props.handleShareScreen();
                        props.handleClose();
                    }}>
                    Share Screen
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export function WrongMeetingRoomModal(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className='modal-header-title'>
                <Modal.Header>
                    <div>
                        <FontAwesomeIcon icon={faDisplay as IconProp} style={{ marginRight: '8px' }} />
                        Wrong Pairing Code
                    </div>
                    <div className='modal-close-btn'>
                        <FontAwesomeIcon icon={faXmark as IconProp} onClick={props.handleClose} className="custom-close-btn" style={{ width: "100%" }} />
                    </div>
                </Modal.Header>
            </div>

            <Modal.Body>
            Please enter a valid pairing code.
            </Modal.Body>

            <Modal.Footer>
                <button className="button-secondary" type='button' onClick={props.handleClose}>Close</button>
            </Modal.Footer>
        </Modal>
    );
}