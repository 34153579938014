import FieldHeader from './FieldHeader';
import { useTranslation } from 'react-i18next'
import './CustomSwitch.scss';
import Switch from 'react-switch';
import { propTypes } from 'react-bootstrap/esm/Image';

interface IProps {
    check: boolean;
    onChange: (e: boolean) => void;
    name: string;
    description: string;
    hideDescription?:boolean;
}

const CustomSwitch = (props: IProps) => {

    const { t } = useTranslation();


    return (
        <div className="accountStatusDiv">
            <FieldHeader name={props.name} description={props.description} hideDescription={props.hideDescription}/>
            <div className="custom-toggle-switch-wrapper">
                <Switch
                    onChange={(e) => props.onChange(e)}
                    checked={props.check}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={16}
                    width={32}
                    onHandleColor='#FFFFFF'
                    onColor='#23A866'
                    offColor='#C6C8CE'
                    offHandleColor='#FFFFFF'
                    className="toggle-switch"
                />
                {props.check && <span>{t('PortalApp.Enabled')}</span>}
                {!props.check && <span>{t('PortalApp.Disabled')}</span>}
            </div>
        </div>

    );
}

export default CustomSwitch;