import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useWebSocket, { Options as WebSocketOptions} from "react-use-websocket";

import {RTCPeerInfo} from "../webrtc/Messaging";
import {LayoutItem, ConnectionMapping} from '../components/ScreenSharePanel';


const SAPPHIRE_API = "sapphire-api";
const POSSIBLE_PREFIXES = ["meet.", "sapphire."]

export interface ISapphirePortalComm {
    availableMeetingRooms: string[];
    registerClient: () => void;
    webrtcAnswer: RTCPeerInfo;
    isMeetingRoomAvailable: (meetingRoomID: string) => Promise<boolean>;
    isMeetingRoomOccupied: (meetingRoomID: string) => Promise<boolean>;
    updateCastingMeetingRoom: (isCasting: boolean, pairingCode: string, guestName: string) => void;
    sendOfferForMeetingRoom: (pairingCode: string, guestName: string, offer: RTCPeerInfo) => void;
    sendCloseConnection: (meetingRoomID: string) => void;
    setLayoutData: (connectionMapping:ConnectionMapping[], layout: LayoutItem[]) => void;
    getLayoutFromDatabase:() => void;
    setLayoutAndSendToAllUponChange: (connectionMapping:ConnectionMapping[], layout: LayoutItem[], layoutType:string) => void;
    setLayoutTypeAndSendAll:(layoutType:string) => void;
    layoutType:string|null;
    setLayoutType:(layoutType:string|null) => void;
    sendLayoutToTheSource:(connectionMapping:ConnectionMapping[], layout: LayoutItem[]) => void;
    broadcastButton:(buttonStatus:Array<any>)=> void;
    setMeetingStatus:(pairingCode: string, currentGuest: string, status:boolean)=> void;
    sendHLSLink:(pairingCode: string, currentGuest: string, sourceName:string, link:string)=> void;
    meetingRoomName:string;
    webexUniqueID:string;
}

export default function useSapphirePortalComm(setButtonBroadcastRecievedFromOtherClients:(buttonBroadcastRecievedFromOtherClients:Array<any>) => void, setRoomosDevice:(roomosDevice:string) => void, setGlobalStateLink:(globalStateLink:string) => void, setWebexAuthToken:(webexAuthToken:string) => void, setMeetingRoomID:(meetingRoomID: string) => void, setConnectionMapping:(connectionMapping:ConnectionMapping[])  => void, layout: LayoutItem[], currentGuests:object, setCurrentGuests:(currentGuests:object)  => void, setLayout: (layout: LayoutItem[]) => void, options: { onOpen:()=>void}) {
    let webSocketOptions:WebSocketOptions = {
        share: true,
        shouldReconnect: (closeEvent) => {
            console.log("websocket closed. closeEvent=" + JSON.stringify(closeEvent));
            return true;
        },
        reconnectAttempts: 1000,
        reconnectInterval: 3000,
    };
    if (options) {
        webSocketOptions.onOpen = options.onOpen;
    }


    const meetingRoomIDInWS = useRef("");

    const sapphireWebsocketURL = useMemo(() => {
        const currentPageHost = window.location.hostname;
        const currentPagePort = window.location.port;
        if (currentPageHost === "localhost") {
            // This is in a dev enviornment. 
            return "wss://sapphire-api.userful.net";
        }
        const pathname = window.location.pathname;
        console.log("pathname: " + pathname);
        if (pathname.startsWith("/meet")) {
            // This is air-gapped.
            return "wss://" + currentPageHost + (currentPagePort ? ":" + currentPagePort : "") + "/sapphire-service";
        } else {
            // This is cloud solution.
            let suffix = currentPageHost;
            for (const prefix of POSSIBLE_PREFIXES) {
                const i = currentPageHost.indexOf(prefix);
                if (i >= 0) {
                    suffix = currentPageHost.substring(i + prefix.length);
                    break;
                }
            }

            console.log("suffix:" + suffix);        
            return "wss://" + SAPPHIRE_API + "." + suffix;
        }
    }, []);

    console.log("sapphireWebsocketURL:" + sapphireWebsocketURL);     

    const {sendJsonMessage, lastJsonMessage} = useWebSocket(sapphireWebsocketURL, webSocketOptions);
    const [availableMeetingRooms, setAvailableMeetingRooms] = useState<string[]>([]);
    const [webexUniqueID, setWebexUniqueID] = useState<string>("");
    const [webrtcAnswer, setWebrtcAnswer] = useState<RTCPeerInfo>(null);
    const [layoutType, setLayoutType] = useState<string|null>("equal");


    const apiCallResponsePromiseRef = useRef<{}>({});

    //senitizes layoutType
    const setSenizitedLayoutType = (recievedLayoutType = null) => {
        setLayoutType(recievedLayoutType);
    }

    const genCallID = useCallback(() => {
        const length = 6;
        let result           = '';
        const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }, [])
    
    const registerClient = useCallback(() => {
        console.log("Register sapphire client...");
        let command = {action: "registerClient", "clientType": "portal"};
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    const getLayoutFromDatabase = useCallback(() => {
        console.log("getLayoutFromDatabase...");
        let command = {action: "getLayoutFromDatabase", "meetingRoomID": meetingRoomIDInWS.current};
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    const setLayoutAndSendToAllUponChange = useCallback((connectionMapping:ConnectionMapping[], layout: LayoutItem[], layoutType:string) => {
        console.log("SetLayoutAndSendToAllUponChange...");
        let command = {action: "setLayoutAndSendToAllUponChange", "meetingRoomID": meetingRoomIDInWS.current, "connectionMapping" : connectionMapping, "layout": layout};
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    const setLayoutData = useCallback((connectionMapping:ConnectionMapping[], layout: LayoutItem[]) => {
        console.log("Send answer...");
        let command = {action: "getLayout", "meetingRoomID": meetingRoomIDInWS.current, "connectionMapping" : connectionMapping, "layout": layout};
        sendJsonMessage(command);
    }, [sendJsonMessage]);


    const sendLayoutToTheSource = useCallback((connectionMapping:ConnectionMapping[], layout: LayoutItem[]) => {
        console.log("sendLayoutToTheSource...");
        let command = {action: "sendLayoutToTheSource", "meetingRoomID": meetingRoomIDInWS.current, "connectionMapping" : connectionMapping, "layout": layout};
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    const setLayoutTypeAndSendAll = useCallback((layoutType:string) => {
        console.log("Set layout type...");
        let command = {action: "setLayoutTypeAndSendAll", "meetingRoomID": meetingRoomIDInWS.current, layoutType};
        console.log(command);
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    const isMeetingRoomAvailable = useCallback((pairingCode:string) => {
        console.log("isMeetingRoomAvailable...");
        const callID = genCallID();
        let command = {action: "isMeetingRoomAvailable", pairingCode: pairingCode, callID};
        sendJsonMessage(command);

        return new Promise<boolean>((resolve, reject) => {
            apiCallResponsePromiseRef.current[callID] = {resolve, reject};
        })        
    }, [sendJsonMessage]);

    const broadcastButton = useCallback((buttonStatus:Array<any>) => {
        console.log("broadcastButton...");
        const callID = genCallID();
        let command = {action: "broadcastButton", "meetingRoomID": meetingRoomIDInWS.current, "buttonStatus":buttonStatus};
        sendJsonMessage(command);       
    }, [sendJsonMessage]);

    const isMeetingRoomOccupied = useCallback((pairingCode:string) => {
        console.log("isMeetingRoomOccupied...");
        const callID = genCallID();
        const command = {action: "isMeetingRoomOccupied", pairingCode: pairingCode, callID};
        sendJsonMessage(command);

        return new Promise<boolean>((resolve, reject) => {
            apiCallResponsePromiseRef.current[callID] = {resolve, reject};
        })
    }, [sendJsonMessage]);

    const updateCastingMeetingRoom = useCallback((isCasting:boolean, pairingCode:string, guestName:string) => {
        console.log("updateCastingMeetingRoom...");
        let command = {action: "updateCastingMeetingRoom", isCasting, pairingCode, guestName};
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    const sendOfferForMeetingRoom = useCallback((pairingCode:string, guestName: string, offer:RTCPeerInfo) => {
        console.log("Send offer...");
        let command = {action: "sendOffer", pairingCode: pairingCode, guestName, offer};
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    const setMeetingStatus = useCallback((pairingCode:string, currentGuest: string, status:boolean) => {
        console.log("Set Meeting Status...");
        let command = {action: "setMeetingStatus", pairingCode: pairingCode, currentGuest, "status": status};
        console.log(command);
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    const sendHLSLink = useCallback((pairingCode:string, currentGuest: string, sourceName:string, link:string) => {
        console.log("Set Meeting Status...");
        let command = {action: "sendHLSLink", pairingCode: pairingCode, currentGuest, "sourceName": sourceName, "link": link};
        console.log(command);
        sendJsonMessage(command);
    }, [sendJsonMessage]);


    const sendCloseConnection = useCallback((meetingRoomID:string) => {
        console.log("Close connection...");
        let command = {action: "closeConnection", meetingRoomID};
        sendJsonMessage(command);
    }, [sendJsonMessage]);

    useEffect(() => {
        if (lastJsonMessage) {
            const result = lastJsonMessage;
            if (typeof result === "object") {
                if (result.action) {
                    if (result.action === "responseAvailableMeetingRooms") {
                        if (result.availableMeetingRooms && Array.isArray(result.availableMeetingRooms)) {
                            setAvailableMeetingRooms(
                                result.availableMeetingRooms.map(o => {
                                    return o.meeting_room_id;
                                })
                            );
                        }
                    } else if (result.action === "relayAnswer") {
                        if (typeof result.answer === "object") {
                            setWebrtcAnswer(result.answer);
                        }
                    }else if (result.action === "responseGetLayoutFromDatabase") {
                        setLayout(result.layout);
                        setConnectionMapping(result.connectionMapping);
                        setSenizitedLayoutType(result.layoutType);
                    }else if (result.action === "sendLayoutToAll") {
                        setLayout(result.results.layout);
                        setConnectionMapping(result.results.connection_mapping);
                        setSenizitedLayoutType(result.layoutType);
                    }else if (result.action === "responseLayoutType") {
                        setSenizitedLayoutType(result.results);
                    }else if (result.action === "responseBroadcastButton") {
                        setButtonBroadcastRecievedFromOtherClients(result.buttonStatus);
                    }else if (result.action === "responseSetLayoutAndSendToAllUponChange") {
                        setLayout(result.results.layout);
                        setConnectionMapping(result.results.connection_mapping);
                    } else if (result.action === "responseIsMeetingRoomAvailable") {
                        if (typeof result.available === "boolean") {
                            setGlobalStateLink(result.global_state_link)
                            setWebexUniqueID(result.webex_unique_id)
                            if(result.roomosDevice && result.webexAuthToken){
                                setRoomosDevice(result.roomosDevice);
                                setWebexAuthToken(result.webexAuthToken);
                            }
                            
                            if (result.callID && apiCallResponsePromiseRef.current[result.callID] && result.available === true) {
                                apiCallResponsePromiseRef.current[result.callID].resolve(result.available);
                                delete apiCallResponsePromiseRef.current[result.callID]
                            } else {
                                apiCallResponsePromiseRef.current[result.callID].resolve(result.available);
                                delete apiCallResponsePromiseRef.current[result.callID]
                            }
                        } else {
                            if (result.callID && apiCallResponsePromiseRef.current[result.callID]) {
                                apiCallResponsePromiseRef.current[result.callID].reject("no available field");
                                delete apiCallResponsePromiseRef.current[result.callID]
                            }
                        }
                    } else if (result.action === "responseIsMeetingRoomOccupied") {
                        if (typeof result.occupied === "boolean") {
                            setMeetingRoomID(result.meetingRoomID);
                            meetingRoomIDInWS.current = result.meetingRoomID;
                            if (result.callID && apiCallResponsePromiseRef.current[result.callID]) {
                                apiCallResponsePromiseRef.current[result.callID].resolve(result.occupied);
                                setCurrentGuests(result.guestNames);
                                delete apiCallResponsePromiseRef.current[result.callID]
                            }
                        } else {
                            if (result.callID && apiCallResponsePromiseRef.current[result.callID]) {
                                apiCallResponsePromiseRef.current[result.callID].reject("no occupied field");
                                delete apiCallResponsePromiseRef.current[result.callID]
                            }
                        }
                    }
                }
            }
        }
    }, [lastJsonMessage]);


    return {webexUniqueID, availableMeetingRooms, sendHLSLink, setMeetingStatus, layoutType, setLayoutType, broadcastButton, sendLayoutToTheSource, setLayoutTypeAndSendAll, getLayoutFromDatabase, setLayoutAndSendToAllUponChange, setLayoutData, registerClient, isMeetingRoomAvailable, isMeetingRoomOccupied, updateCastingMeetingRoom, webrtcAnswer, sendOfferForMeetingRoom, sendCloseConnection,meetingRoomName : meetingRoomIDInWS.current};

}
