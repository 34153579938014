import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import 'font-awesome/css/font-awesome.min.css';

import ScreenSharePanel from './ScreenSharePanel';
import { LayoutItem, ConnectionMapping } from './ScreenSharePanel';
import SelectLayout from './SelectLayout';
import AddConference from './AddConference';
import { ISapphirePortalComm } from '../comm/WebSocket';
import ConferenceActions from './ConferenceActions';
import { PostRoomOS } from "../roomos/Command";
import SessionKeeper from '../resourcesharing/SessionKeeper'
import ResourcesBar from '../resourcesharing/ResourcesBar';
import './PortalPanel.scss';
import './css/sourcesform.scss';
import './css/InputPanel.scss';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faShare } from '@fortawesome/pro-regular-svg-icons';
import { stopPoller } from "../roomos/Poller";
import VideoConferencingPage from "./VideoConferencingPage";
import SelectConferenceType from "./SelectConferenceType";
interface IProps {
    setSelectedRoomOSDevice: (device: object) => void,
    roomOSDevices: Array<any>,
    pairingCode: string,
    meetingRoomID: string,
    endCasting: () => void,
    muteSound: () => void,
    isCasting: boolean,
    // isMuted: ()=>boolean,
    layout: Array<LayoutItem>;
    localLayout: Array<LayoutItem>;
    setLayout: (layout: LayoutItem[]) => void;
    setLocalLayout: (layout: LayoutItem[]) => void;
    currentGuests: object;
    guestName: string;
    connectionMapping: Array<ConnectionMapping>;
    setLayoutData: (connectionMapping: ConnectionMapping[], layout: LayoutItem[]) => void;
    setLayoutAndSendToAllUponChange: (connectionMapping: ConnectionMapping[], layout: LayoutItem[], layoutType:string) => void;
    setLayoutTypeAndSendAll: (layoutType: string) => void;
    layoutType: string|null;
    setLayoutType: (layoutType: string|null) => void;
    sendLayoutToTheSource: (connectionMapping: ConnectionMapping[], layout: LayoutItem[], layoutType:string) => void;
    roomosDevice: string;
    webexAuthToken: string;
    conferanceButtons: Array<any>;
    sapphirePortalComm: ISapphirePortalComm;
    startCasting: (callback?:Function) => void;
    setMeeting: (inMeeting: boolean) => void;
    inMeeting: boolean;
    sendHLSLink: (pairingCode: string, currentGuest: string, sourceName:string, link: string) => void;
    globalStateLink: string;
    buttonBroadcastRecievedFromOtherClients: Array<any>;
    videoConferencePage: boolean;
    setVideoConference: Function;
    setGuestName: Function;
    uCast: boolean;
    setUCast: Function;
    conferenceType: number;
    setConferenceType: Function;
    setSelectedApp: Function;
    setAdmitAll:Function;
    admitAll:boolean;

}

/**
 * This is rendered once user enters the pairing code and joins the spaces
 *  */
export default function PortalPanel(props: IProps) {
    const navigate = useNavigate();

    // const [isMuted, setIsMuted] = useState(props.isMuted);

    const { t } = useTranslation();

    const [doubleClickForSpotlight, setDoubleClickForSpotlight] = useState(true);
    const [localLayout, setLocalLayout] = useState([]);
    const [isShowPresentPanel, setShowPresentPanel] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string>("equal");
    const [isConferanceActive, setIsConferanceActive] = useState<boolean>(false);
    const [currentConferance, setCurrentConferance] = useState<Array<any>>([0, '', false, '']);
    const [currentSources, setCurrentSources] = useState<Array<any>>([]);
    const [resourceSharingButton, setResourceSharingButton] = useState<boolean>(false);
    const [sourcesBarHidden, setSourcesBarHidden] = useState<boolean>(false);
    const [isInTheSameNetwork, setInTheSameNetwork] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState("");
    const [getUserUUID, setUserUUID] = useState("");
    const [conferenceFormOpen, setConferenceFormOpen] = useState(false);

    const meetingOnline = useRef(null);
    const callId = useRef(-1);

    // const handleMuteClick = () => {
    //     props.muteSound();
    //     setIsMuted(props.isMuted);
    // };

    const checkSameNetworkAsServer = async (url) => {
        if (url.startsWith("wss://")) {
            const websocket = new WebSocket(url);
            websocket.onopen = function (event) {
                console.log('WebSocket connection established:', event);
                setInTheSameNetwork(true);
                websocket.close();
                return true;
            };
            websocket.onerror = function (event) {
                console.error('WebSocket error:', event);
                return false;
            };
        } else if (url.startsWith("ws://") && window.location.hostname.includes("localhost")) {
            const websocket = new WebSocket(url);
            websocket.onopen = function (event) {
                console.log('WebSocket connection established:', event);
                setInTheSameNetwork(true);
                websocket.close();
                return true;
            };
            websocket.onerror = function (event) {
                console.error('WebSocket error:', event);
                return false;
            };
        } else {
            console.log("Invalid WebSocket URL");
            return true;
        }
    };

    function addUrlParameters(baseUrl: string, link: string): string {
        const url = new URL(baseUrl);

        url.searchParams.append("openerLink", link);

        return url.toString();
    }



    const [conferance, setConferance] = useState<boolean>(props.conferanceButtons[0]);
    const [minimize, setMinimize] = useState<boolean>(props.conferanceButtons[1]);
    const statusInterval = useRef(null);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    useEffect(() => {
        setShowPresentPanel(true);
    }, [props.inMeeting]);


    useEffect(() => {
        window.addEventListener("beforeunload", (e) => {eventListenerCloseTab(e, props.pairingCode, props.guestName)});

        return () => {
            window.removeEventListener("beforeunload", (e) => {eventListenerCloseTab(e, props.pairingCode, props.guestName)});
        };
    }, [props.guestName]);


    useEffect(() => {
        if (props.globalStateLink === null && window.location.href.includes("localhost")) {
            setInTheSameNetwork(true);
            return;
        }
        if (props.globalStateLink !== "") {
            const url = props.globalStateLink.replace(/^http:/, 'ws:').replace(/^https:/, 'wss:');
            var intervalID = setInterval(() => {
                var withinTheNetwork = checkSameNetworkAsServer(url + "/sockstream");
                if (withinTheNetwork) {
                    clearInterval(intervalID);
                }
            }, 2000);
        }
    }, [props.globalStateLink]);

    useEffect(() => {
        props.sapphirePortalComm.updateCastingMeetingRoom(props.inMeeting, props.pairingCode, props.inMeeting ? props.guestName : "");
        props.sapphirePortalComm.isMeetingRoomOccupied(props.pairingCode);
        if (props.inMeeting) {
          meetingOnline.current = setInterval(() => {
            props.sapphirePortalComm.setMeetingStatus(props.pairingCode, props.guestName, true);
          }, 2000);
        }
        else {
          if (meetingOnline.current) clearInterval(meetingOnline.current);
          props.sapphirePortalComm.setMeetingStatus(props.pairingCode, props.guestName, false);
        }
        setUserUUID(props.guestName);
        
        return () => {
          if (meetingOnline.current) clearInterval(meetingOnline.current);
          props.sapphirePortalComm.setMeetingStatus(props.pairingCode, props.guestName, false);
        };
    }, [props.inMeeting]);

    const getStreamActivateUrl = (globalStateLink) => {
        const isLocalhost = window.location.hostname.includes("localhost");
        const isPort3001 = window.location.href.includes("3001");
        const baseLocalUrl = isPort3001 ? "http://localhost:3000/streamActivate"
            : "http://localhost:3001/streamActivate";
        const baseGlobalUrl = globalStateLink + "/spaces/streamActivate";
        const baseUrl = isLocalhost ? baseLocalUrl : baseGlobalUrl;
        return addUrlParameters(baseUrl, window.location.href);
    };

    const joinSpace = () => {
        // if uCast and conference type is not selected
        // none selected
        if(props.conferenceType === -1 && !props.uCast){
            props.setVideoConference(false);
            return;
        }
        // if conference is selected
        if (props.conferenceType !== -1) {
            setConferenceFormOpen(true);
            return;
        }
        // this will only run when conference is not selected but uCast is selected 
        if (props.uCast) {
                props.startCasting(() => {
                    props.setVideoConference(false);
            });
        } 
    }

    const handleLeaveSpace = async (pairingCode) => {
        if (Object.keys(props.currentGuests).length === 1 && isConferanceActive) {
            await PostRoomOS(props.roomosDevice,
                "Call.Disconnect",
                {},
                props.meetingRoomID + props.sapphirePortalComm.webexUniqueID);
        }

        if (props.isCasting) {

            props.endCasting();
        }
        props.setMeeting(false);
        props.sapphirePortalComm.sendCloseConnection(pairingCode);
        props.setUCast(false);
        props.setConferenceType(-1);
        navigate("/");
    }

    const handleStopCasting = async () => {
        if (props.isCasting) {
            props.endCasting();
        }
    }


    const handleLeaveSpaceWithTab = (pairingCode, guestName) => {
        props.sapphirePortalComm.setMeetingStatus(pairingCode, guestName, false);
        try {
            if (props.isCasting) {
                props.endCasting();
            }
            props.sapphirePortalComm.sendCloseConnection(pairingCode);
        } catch (error) {
            console.log(error);
        }
    }

    //Wrapper function for event call
    function eventListenerCloseTab(e, pairingCode, guestName) {
        handleLeaveSpaceWithTab(pairingCode, guestName);
    }

    const handleResourceSharing = () => {
        if (!resourceSharingButton) {
            setResourceSharingButton(true);
        }
        else if (resourceSharingButton) {
            setResourceSharingButton(false);

        }
    }

    var leaveConferance = async () => {

        try {
            await PostRoomOS(props.roomosDevice,
                "Call.Disconnect",
                {},
                props.meetingRoomID + props.sapphirePortalComm.webexUniqueID)
            await PostRoomOS(props.roomosDevice,
                "Presentation.Start",
                {
                    "Instance": "3",
                    "Layout": "Prominent",
                    "PresentationSource": ["3"],
                    "SendingMode": "LocalRemote"
                },
                props.meetingRoomID + props.sapphirePortalComm.webexUniqueID)
            setConferance(false);
            setMinimize(true);
            setIsConferanceActive(false);
        }
        catch (error) {
            setError('An error occurred while trying to start the conference. ' + error.message);
            handleShow();  // Show the modal
        }
    };

    var leaveConferanceEvent = async () => {
        stopPoller();
        clearInterval(statusInterval.current);
        leaveConferance();
        // Stop the poller if it exists
    }

    if (props.videoConferencePage) {
        return (
            <>
                <VideoConferencingPage
                    meetingRoomName={props.meetingRoomID}
                    guestName={props.guestName}
                    setGuestName={props.setGuestName}
                    uCast={props.uCast}
                    setUCast={props.setUCast}
                    handleLeaveSpace={() => {handleLeaveSpace(props.pairingCode)}}
                    joinSpace={joinSpace}
                    conferenceType={props.conferenceType}
                    setConferenceType={props.setConferenceType}
                    disableConfrenceOptions={!(props.roomosDevice && props.meetingRoomID)} 
                    startCasting={props.startCasting}/>
                {props.roomosDevice && props.meetingRoomID && <AddConference
                    meetingRoomID={props.meetingRoomID}
                    callId={callId.current}
                    currentConferance={currentConferance}
                    setCurrentConferance={setCurrentConferance}
                    setCallId={(value) => callId.current = value}
                    setIsConferanceActive={setIsConferanceActive}
                    sapphirePortalComm={props.sapphirePortalComm}
                    conferanceButtons={props.conferanceButtons}
                    roomosDevice={props.roomosDevice}
                    webexAuthToken={props.webexAuthToken}
                    roomOSDevices={props.roomOSDevices}
                    setSelectedRoomOSDevice={props.setSelectedRoomOSDevice}
                    isConferanceActive={isConferanceActive}
                    showConferenceButton
                    conference={conferance}
                    minimize={minimize}
                    setMinimize={setMinimize}
                    setConference={setConferance}
                    statusInterval={statusInterval}
                    leaveConferance={leaveConferance}
                    error={error}
                    showModal={showModal}
                    setError={(error) => setError(error)}
                    handleClose={handleClose}
                    handleShow={handleShow}
                    conferenceFormOpen={conferenceFormOpen}
                    setConferenceFormOpen={(bool) => setConferenceFormOpen(bool)}
                    selectedApp={props.conferenceType}
                    setSelectedApp={props.setSelectedApp}
                    setVideoConferance={props.setVideoConference}
                    setAdmitAll={props.setAdmitAll}
                    admitAll={props.admitAll}
                    callback={()=>{
                        if (props.uCast) {
                            props.startCasting(() => {
                                props.setVideoConference(false);

                            });
                        } 
                        else{
                            props.setVideoConference(false);
                        }
                    }}
                />}
            </>);
    }

    return (
        <div className="overallPanelLayout">
            <div className="sharingPanel">
                <div className="sharingPanelLeft" style={{ width: !isConferanceActive ? 'calc(100vw - 468px)' : '100%' }}>
                    <div className="sharingPanelLeftBar">
                        {/**Leave Space button at top */}
                        <Button variant={'danger'} onClick={() => {handleLeaveSpace(props.pairingCode)}}>
                            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "8px" }} className="fontAwesomeCustomIcon" />
                            {t('PortalApp.LeaveSpace')}
                        </Button>
                        <div className="sharingButtons">
                            {/**Share Screen button */}
                            {!props.isCasting && <Button variant={'secondary'} onClick={(event) => {props.startCasting()}} disabled={props.connectionMapping.length === 6}>
                                <FontAwesomeIcon icon={faShare} style={{ marginRight: "8px" }} className="fontAwesomeCustomIcon" />
                                {t('PortalApp.ShareScreen')}
                            </Button>}
                            {/** Stop Sharing button */}
                            {props.isCasting && <Button variant={'secondary'} onClick={handleStopCasting}>
                                <FontAwesomeIcon icon={faXmark} style={{ marginRight: "8px" }} className="fontAwesomeCustomIcon" />
                                {t('PortalApp.StopSharing')}
                            </Button>}
                            {isInTheSameNetwork && currentSources.length === 0 && <Button variant={'secondary'} onClick={handleResourceSharing}>
                                <FontAwesomeIcon icon={faShare} style={{ marginRight: "8px" }} className="fontAwesomeCustomIcon" />
                                {t('PortalApp.ActivateResourceSharing')}
                            </Button>}
                        </div>
                        {/*This component displays the Select layout button and Add conference button
                        props.connectionMapping.length !== 0 --> this  condition is for the layout button. If its true, then layout button is displayed
                        props.roomosDevice && props.meetingRoomID --> when  this is true, then Add conference button will be displayed
                        So this div tag should be displayed when either one of above condition is true
                    */}
                        {(props.connectionMapping.length !== 0 || (props.roomosDevice && props.meetingRoomID)) && <div className="sharingDivs">
                            {/** This displays the layout button */}
                            <SelectLayout
                                layout={props.layout}
                                selectedValue={selectedValue}
                                setSelectedValue={(e) => setSelectedValue(e)}
                                setLocalLayout={setLocalLayout}
                                layoutType={props.layoutType}
                                setLayoutType={props.setLayoutType}
                                setLayoutTypeAndSendAll={props.setLayoutTypeAndSendAll}
                                sendLayoutToTheSource={props.sendLayoutToTheSource}
                                setDoubleClickForSpotlight={setDoubleClickForSpotlight}
                                connectionMapping={props.connectionMapping}
                                setLayoutAndSendToAllUponChange={props.setLayoutAndSendToAllUponChange}
                                meetingRoomID={props.meetingRoomID} />

                            {/** This displays the Add conference button */}
                            <AddConference
                                meetingRoomID={props.meetingRoomID}
                                callId={callId.current}
                                currentConferance={currentConferance}
                                setCurrentConferance={setCurrentConferance}
                                setCallId={(value) => callId.current = value}
                                setIsConferanceActive={setIsConferanceActive}
                                sapphirePortalComm={props.sapphirePortalComm}
                                conferanceButtons={props.conferanceButtons}
                                roomosDevice={props.roomosDevice}
                                webexAuthToken={props.webexAuthToken}
                                roomOSDevices={props.roomOSDevices}
                                setSelectedRoomOSDevice={props.setSelectedRoomOSDevice}
                                isConferanceActive={isConferanceActive}
                                showConferenceButton
                                conference={conferance}
                                minimize={minimize}
                                setMinimize={setMinimize}
                                setConference={setConferance}
                                statusInterval={statusInterval}
                                leaveConferance={leaveConferance}
                                error={error}
                                showModal={showModal}
                                setError={(error) => setError(error)}
                                handleClose={handleClose}
                                handleShow={handleShow}
                                conferenceFormOpen={conferenceFormOpen}
                                setConferenceFormOpen={(bool) => setConferenceFormOpen(bool)}
                                insidePortalPanel
                                selectedApp={props.conferenceType}
                                setSelectedApp={props.setSelectedApp}
                                setAdmitAll={props.setAdmitAll}
                                admitAll={props.admitAll}
                            />
                        </div>}
                    </div>
                    <ScreenSharePanel
                        setSelectedValue={(e) => setSelectedValue(e)}
                        meetingRoomID={props.meetingRoomID}
                        isShow={isShowPresentPanel}
                        connectionMapping={props.connectionMapping}
                        currentGuests={props.currentGuests}
                        guestName={props.guestName}
                        layout={props.layout}
                        sapphirePortalComm={props.sapphirePortalComm}
                        setLayout={props.setLayout}
                        setLocalLayout={setLocalLayout}
                        setLayoutData={props.setLayoutData}
                        setLayoutAndSendToAllUponChange={props.setLayoutAndSendToAllUponChange}
                        doubleClickForSpotlight={doubleClickForSpotlight}
                        localLayout={localLayout}
                        selectedValue={selectedValue}
                        isConferanceActive={isConferanceActive}
                        displayBar={(currentSources.length > 0 && resourceSharingButton)}
                        sourcesBarHidden={sourcesBarHidden}
                        currentSources={currentSources}
                        isConferenceActive={isConferanceActive}
                    />

                </div>
                {/** Conference Action buttons */}
                {isConferanceActive && <div className="sharingPanelRight">
                    <ConferenceActions
                        meetingRoomID={props.meetingRoomID}
                        webexAuthToken={props.webexAuthToken}
                        roomosDevice={props.roomosDevice}
                        callId={callId.current}
                        currentConferance={currentConferance}
                        setCurrentConferance={setCurrentConferance}
                        conferanceButtons={props.conferanceButtons}
                        sapphirePortalComm={props.sapphirePortalComm}
                        leaveConferanceEvent={leaveConferanceEvent} 
                        admitAll={props.admitAll}/>
                </div>}
            </div>
            {currentSources.length > 0 && <ResourcesBar
                sourcesBarHidden={sourcesBarHidden}
                setSourcesBarHidden={setSourcesBarHidden}
                currentSources={currentSources}
                guestName={getUserUUID}
                pairingCode={props.pairingCode}
                sendHLSLink={props.sendHLSLink}
                searchValue={searchValue}
                setSearchValue={(value) => setSearchValue(value)} />}

            {resourceSharingButton && <SessionKeeper
                globalStateLink={props.globalStateLink}
                setCurrentSources={setCurrentSources}
                url={getStreamActivateUrl(props.globalStateLink)} />}

        </div>

    )

}








