import UserfulTooltip from "./UserfulTooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListOl, faCube, faGrid2Plus } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from "react-i18next";
import './ResourceCard.scss';

interface IProps {

    // this gives the name of source or playlist
    insets: any;
    // tells if its a mapping group or multi-window
    mapperName: String;
    // determines if its playlist or not. if NOT_SET, then its a source, else its a playlist
    // createdFrom: string;
    onClick: Function;

}

export const MULTI_WINDOW_GROUP_PREFIX = 'Auto_Created_Multi_Window_';

export const isMappingGroupMultiWindow = (mappingGroup): boolean => mappingGroup.startsWith(MULTI_WINDOW_GROUP_PREFIX);

export const getMultiWindowGroupName = (data): string => {
    if (!data) {
        return "Unknown";
    }
    if (typeof data === "string") {
        return (data as string).replace(MULTI_WINDOW_GROUP_PREFIX, "");
    }
    return (data).name.replace(MULTI_WINDOW_GROUP_PREFIX, "");
};

const ResourceCard = (props: IProps) => {

    const { t } = useTranslation();

    // this case is for if no source is mapped to a mapping group
    if (props.insets.length === 0)
        return null;

    const isMultiwindow = isMappingGroupMultiWindow(props.mapperName);

    const title = isMultiwindow ? getMultiWindowGroupName(props.mapperName) : props.insets[0].name;
    const createdFrom = props.insets[0].playList.createdFrom.value;
    const titleTip = isMultiwindow ? t('PortalApp.Multiwindow') : createdFrom === "NOT_SET" ? t("PortalApp.Source") : t("PortalApp.Playlist");
    const colorName = createdFrom === "NOT_SET" ? 'blueInfo' : 'lightPurpleInfo'
    const icon = isMultiwindow ? faGrid2Plus : createdFrom === "NOT_SET" ? faCube : faListOl;
    const bgimage = isMultiwindow ? './assets/multiwindow.png' : createdFrom === "NOT_SET" ? './assets/source.png' : './assets/Playlist_background.png';


    return (
        <div className="usefulCard" onClick={() => props.onClick()}>
            <div className="infoBar">
                <div className="infoBarElements">
                    <UserfulTooltip placement="top" title={titleTip}>
                        <div className={colorName}>
                            <FontAwesomeIcon icon={icon} />
                        </div>
                    </UserfulTooltip>
                </div>

            </div>
            {title.length > 14 ? (
                <UserfulTooltip placement="top" title={title}>
                    <div className="nameBar">
                        <div className="nameBarText">{title}</div>
                    </div>
                </UserfulTooltip>
            ) : (
                <div className="nameBar">
                    <div className="nameBarText">{title}</div>
                </div>
            )}
            <div className="contentBar">
                <img className="image" src={bgimage} alt="sourceBackgroundImage" draggable={false} />
            </div>
        </div>
    );
}

export default ResourceCard;