let interval = 0;
let callback = async (...args) => {};
let timeoutId = null;
let lastStatus = null;

export const startPoller = (...args) => {
  if (timeoutId) {
    stopPoller();
  }
  const poll = async () => {
    try {
      // Save the result of the callback to lastStatus
      lastStatus = await callback(...args);
    } catch (error) {
      console.error('Error during polling:', error);
    }
    timeoutId = setTimeout(poll, interval);
  };
  poll(); 
};

export const stopPoller = () => {
  if (timeoutId !== null) {
    clearTimeout(timeoutId);
    timeoutId = null;
  }
};

export const statusPoller = () => {
  return lastStatus;
};

export const setPollerCallback = (newCallback) => {
  callback = newCallback;
};

export const setPollerInterval = (newInterval) => {
  interval = newInterval;
};
