import { Offcanvas } from 'react-bootstrap';
import FormHeader from '../../common/FormHeader';
import { useTranslation } from 'react-i18next';
import StaticValueCopyField from '../../common/StaticValueCopyField';
import CommonTable from '../../common/CommonTable';
import FieldHeader from '../../common/FieldHeader';
import CustomSearch from '../../common/CustomSearch';
import {useState} from 'react';
interface IProps {
    roomosDevice: string,
    webexAuthToken: string,
    setSidebarOpen: (sidebarOpen: boolean) => void;
    participants: Array<any>;
    currentConferance: Array<any>;
    setCurrentConferance: (currentConferance: Array<any>) => void;
    sidebarOpen: boolean;
}

export default function ParticipantsForm(props: IProps) {

    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState("");

    const getSearchValue = (value) => {
        setSearchValue(value);
    }

    const handleCancel = () => {
        props.setSidebarOpen(false);
    };


    const participantsList = props.participants.map((user) => ({ value: user.DisplayName }));
    const filterResult = searchValue ? participantsList.filter((user)=> user.value.includes(searchValue)) : participantsList;

    return (
        <Offcanvas show={props.sidebarOpen} onHide={handleCancel} placement="end" className="createUserForm">
            <Offcanvas.Body>
                <div className="overallUserForm">
                    <FormHeader handleClose={() => handleCancel()} name={t('PortalApp.ConferenceParticipants')} description={t('PortalApp.ConferenceParticipantsDes')} />
                    <StaticValueCopyField
                        handleCopy={() => { }}
                        value={props.currentConferance[1]}
                        hoverOverText={t('PortalApp.CopyMeetingRoomURL')}
                        title={t('PortalApp.MeetingSpaceURL')}
                        width="492px" />
                    <div className='userDetailsDiv'>
                        <FieldHeader name={t('PortalApp.ConferencingParticipants')} description={t('PortalApp.ConferencingParticipantsDes')}/>
                        <CustomSearch searchValue={searchValue} getSearchValue={getSearchValue} setSearchValue={setSearchValue}/>
                        <CommonTable data={filterResult} heading={t('PortalApp.Participants')}/>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas >
    );
}