import { useTranslation } from 'react-i18next';
import './CommonTable.scss';

interface IProps {
    data: Array<{ value: string }>
    heading: string;
}

export default function CommonTable(props: IProps) {
    const { t } = useTranslation();

    return (

        <div className="overallTableHead">
            <div className="tableHead">
                <div className="headText">
                    {props.heading}
                </div>
            </div>
            <div className='monitoring-table-wrapper' >

                <table id="participants-table" style={{ width: '100%' }} >
                    <tbody>
                        {props.data.map((row) => (
                            <tr key={row.value}>
                                <td><div className="textDiv">{row.value}</div></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div >
    );
};



