import React, { useState } from 'react';
import { useEffect } from 'react';
import { LayoutItem, ConnectionMapping } from './ScreenSharePanel';
import { Dropdown } from "react-bootstrap";
import CustomToggle from "../common/CustomToggle";
import CustomMenu from "../common/CustomMenu";
import FilterDropdownButton from '../common/FilterDropdownButton';
import { useTranslation } from 'react-i18next';
import './SelectLayout.scss';
import './css/sourcesform.scss';
import './css/InputPanel.scss';
import { faChartTreeMap, faTableCellsLarge } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserfulTooltip from '../common/UserfulTooltip';
interface IProps {
    meetingRoomID: string,
    layout: Array<LayoutItem>;
    setLocalLayout: (layout: LayoutItem[]) => void;
    setDoubleClickForSpotlight: (doubleClickForSpotlight: boolean) => void;
    connectionMapping: Array<ConnectionMapping>;
    setLayoutAndSendToAllUponChange: (connectionMapping: ConnectionMapping[], layout: LayoutItem[], layoutType:string) => void;
    setLayoutTypeAndSendAll: (layoutType: string) => void;
    layoutType: string|null;
    setLayoutType: (layoutType: string|null) => void;
    sendLayoutToTheSource: (connectionMapping: ConnectionMapping[], layout: LayoutItem[], layoutType:string) => void;
    setSelectedValue: (selectedValue: string) => void;
    selectedValue: string;
}
// event is either spotlight or equal
// listLayout is the current list of layouts
// connectionMapping has the list of participants
export const getLayoutData = (listLayout, event, connectionMapping) => {

    var mainLayoutUpdate = [];
    var secondaryIndex = 0;
    const len = listLayout.length;

    if (event === 'spotlight') {

        listLayout.forEach((frame, index) => {
            if (frame.i === connectionMapping[0].mainConnectionID) {
                // h is 8 because this is for spotlight main component, and dividing factor is 8 in PanelView, which means the whole
                // screen is being divided into 8 rows
                mainLayoutUpdate.push({ i: frame.i, x: 0, y: 0, w: listLayout.length > 1 ? 40 : 50, h: 5, static: true, isResizable: false, isDraggable: false })
            }
            else {
                mainLayoutUpdate.push(
                    { i: frame.i, x: 40, y: secondaryIndex++, w: 10, h: 1, static: true, isResizable: false, isDraggable: false }
                );
            }
        })
    }

    if (event === 'equal') {

        listLayout.forEach((frame, index) => {

            // Define base positions without centering
            if (len === 1) {
                mainLayoutUpdate.push({ i: frame.i, x: index * 25, y: 0, w: 50, h: 1, static: true, isResizable: false, isDraggable: false })
            }
            if (len === 2) {
                mainLayoutUpdate.push({ i: frame.i, x: index * 25, y: 0, w: 25, h: 1, static: true, isResizable: false, isDraggable: false })
            }
            else if (len === 3) {
                if (index === 0) mainLayoutUpdate.push({ i: frame.i, x: 5, y: 0, w: 20, h: 1, static: true, isResizable: false, isDraggable: false });
                if (index === 1) mainLayoutUpdate.push({ i: frame.i, x: 25, y: 0, w: 20, h: 1, static: true, isResizable: false, isDraggable: false });
                if (index === 2) mainLayoutUpdate.push({ i: frame.i, x: 15, y: 1, w: 20, h: 1, static: true, isResizable: false, isDraggable: false });
            }
            else if (len === 4) {
                if (index === 0) mainLayoutUpdate.push({ i: frame.i, x: 5, y: 0, w: 20, h: 1, static: true, isResizable: false, isDraggable: false });
                if (index === 1) mainLayoutUpdate.push({ i: frame.i, x: 25, y: 0, w: 20, h: 1, static: true, isResizable: false, isDraggable: false });
                if (index === 2) mainLayoutUpdate.push({ i: frame.i, x: 5, y: 1, w: 20, h: 1, static: true, isResizable: false, isDraggable: false });
                if (index === 3) mainLayoutUpdate.push({ i: frame.i, x: 25, y: 1, w: 20, h: 1, static: true, isResizable: false, isDraggable: false });
            }

            else if (len >= 5) {
                if (index === 0) mainLayoutUpdate.push({ i: frame.i, x: 5, y: 0, w: 40 / 3, h: 1, static: true, isResizable: false, isDraggable: false });
                if (index === 1) mainLayoutUpdate.push({ i: frame.i, x: 5 + (40 / 3), y: 0, w: 40 / 3, h: 1, static: true, isResizable: false, isDraggable: false });
                if (index === 2) mainLayoutUpdate.push({ i: frame.i, x: 5 + (40 / 3) + (40 / 3), y: 0, w: 40 / 3, h: 1, static: true, isResizable: false, isDraggable: false });
                if (index === 3) mainLayoutUpdate.push({ i: frame.i, x: len === 6 ? 5 : 5 + (40 / 6), y: 1, w: 40 / 3, h: 1, static: true, isResizable: false, isDraggable: false });
                if (index === 4) mainLayoutUpdate.push({ i: frame.i, x: len === 6 ? 5 + (40 / 3) : 5 + (40 / 3) + (40 / 6), y: 1, w: 40 / 3, h: 1, static: true, isResizable: false, isDraggable: false });
                if (index === 5) mainLayoutUpdate.push({ i: frame.i, x: 5 + (40 / 3) + (40 / 3), y: 1, w: 40 / 3, h: 1, static: true, isResizable: false, isDraggable: false });
            }
        });
    }

    return mainLayoutUpdate;
}


function SelectLayout(props: IProps) {


    const { t } = useTranslation();

    const LAYOUT_OPTIONS = ['equal', 'spotlight'];

    const [selectedValue, setSelectedValue] = useState<string>("equal");

    props.setLayoutType(null);

    const handleChange = (event) => {
        setSelectedValue(event);
        if (event === 'spotlight') {
            const mainLayoutUpdate = getLayoutData(props.layout, 'spotlight', props.connectionMapping);
            props.setLocalLayout(mainLayoutUpdate);
            props.setLayoutTypeAndSendAll('spotlight');
            props.sendLayoutToTheSource(props.connectionMapping, mainLayoutUpdate, 'spotlight');
            props.setSelectedValue('spotlight');

        }
        else if (event === 'equal') {
            const mainLayoutUpdate = getLayoutData(props.layout, 'equal', props.connectionMapping);
            props.setLocalLayout(mainLayoutUpdate);
            props.setLayoutTypeAndSendAll('equal');
            props.sendLayoutToTheSource(props.connectionMapping, mainLayoutUpdate, 'equal');
            props.setSelectedValue('equal');
        }
    };

    useEffect(() => {
        // for spotlight layout
        if (props.layoutType !== null) {
            const mainLayoutUpdate = getLayoutData(props.layout, props.layoutType, props.connectionMapping);
            setSelectedValue(props.layoutType);
            props.setLocalLayout(mainLayoutUpdate);
            props.setSelectedValue(props.layoutType);
            // for grid layout
        }
    }, [props.layoutType]);

    if (props.connectionMapping.length === 0)
        return <></>

    return (

        <div className='layoutButtons'>
            <div className="layoutText">{t('PortalApp.SpaceLayout')}</div>
            <div className="subnav-header-info">
                <UserfulTooltip title={t('PortalApp.equal')}>
                    <button
                        className={
                            selectedValue === "equal" ? "tabCustomBtn active" : "tabCustomBtn"
                        }
                        name="equal"
                        onClick={() => handleChange("equal")}
                    >
                        <FontAwesomeIcon icon={faTableCellsLarge} />
                    </button>
                </UserfulTooltip>
                <UserfulTooltip title={t('PortalApp.spotlight')}>
                    <button
                        className={
                            selectedValue === "spotlight" ? "tabCustomBtn active" : "tabCustomBtn"
                        }
                        name="spotlight"
                        onClick={() => handleChange("spotlight")}
                    >
                        <FontAwesomeIcon icon={faChartTreeMap} />

                    </button>
                </UserfulTooltip>
            </div>
        </div>

        // <Dropdown className="addBorder">
        //     <Dropdown.Toggle as={CustomToggle} id="mapping-group-sort-toggle" className="custom-dropdown-toggle">
        //         <FilterDropdownButton
        //             name="Layout"
        //             translationText="PortalApp."
        //             filterNameDisplay={`PortalApp.${selectedValue}`}
        //             count={1}
        //             noTranslation={true}
        //             directTranslationOnFilterName={true}
        //         />
        //     </Dropdown.Toggle>

        //     <Dropdown.Menu as={CustomMenu} className="custom-dropdown-menu sortByFont">
        //         {LAYOUT_OPTIONS.map((option) => <Dropdown.Item
        //             as="li"
        //             key={`layout-${option}`}
        //             value={selectedValue}
        //             onClick={() => handleChange(option)}
        //             active={selectedValue === option}
        //         >
        //             {t(`PortalApp.${option}`)}
        //         </Dropdown.Item>)}
        //     </Dropdown.Menu>
        // </Dropdown>
    );
}

export default SelectLayout;
