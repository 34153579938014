import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ISapphirePortalComm } from '../comm/WebSocket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from "react-i18next";
import Button from '../common/Button';
import AddConferenceForm from "./forms/AddConferenceForm";
import './css/sourcesform.scss';
import '../common/css/Modal.scss';
import { faXmark, faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';

interface IProps {
    roomOSDevices: Array<any>,
    setSelectedRoomOSDevice: (device: object) => void,
    roomosDevice: string,
    webexAuthToken: string,
    conferanceButtons: Array<any>;
    sapphirePortalComm: ISapphirePortalComm;
    setIsConferanceActive: (isConferanceActive: boolean) => void;
    setCallId: (callId: number) => void;
    currentConferance: Array<any>;
    setCurrentConferance: (currentConferance: Array<any>) => void;
    callId: number;
    meetingRoomID: string;
    isConferanceActive: boolean;
    // this true when only want to show the conferencing button
    showConferenceButton?: boolean;
    setConference: Function;
    setMinimize: Function;
    conference: boolean;
    minimize: boolean;
    statusInterval: any;
    leaveConferance: Function;
    error: string;
    showModal: boolean;
    setError: Function;
    handleClose: Function;
    handleShow: Function;
    // state for handling the conference form
    conferenceFormOpen: boolean;
    setConferenceFormOpen: Function;
    insidePortalPanel?:boolean;
    selectedApp:number;
    setSelectedApp:Function;
    setVideoConferance?:Function;
    setAdmitAll:Function;
    admitAll:boolean;
    callback?:Function;
}



export default function AddConference(props: IProps) {

    const { t } = useTranslation();

    // const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);


    const onSetSidebarOpen = open => {
        props.setConferenceFormOpen(open);
    };



    // if roomOS device is not connected
    if (!(props.roomosDevice && props.meetingRoomID))
        return <></>

    // if there is no active conference and we need to show the button to start the conference
    if (props.showConferenceButton && !props.isConferanceActive) {

        return (
            <>
                {props.insidePortalPanel && <Button onClick={() => onSetSidebarOpen(true)} variant="secondary">
                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: "8px" }} className="fontAwesomeCustomIcon" />
                    {t('PortalApp.AddConference')}
                </Button>}
                <AddConferenceForm
                    meetingRoomID={props.meetingRoomID}
                    setMinimize={(minimize) => props.setMinimize(minimize)}
                    conferance={props.conference}
                    setConferance={(conference) => props.setConference(conference)}
                    sapphirePortalComm={props.sapphirePortalComm}
                    handleShow={() => props.handleShow()}
                    setError={(error) => props.setError(error)}
                    roomosDevice={props.roomosDevice}
                    webexAuthToken={props.webexAuthToken}
                    setSidebarOpen={(bool) => props.setConferenceFormOpen(bool)}
                    currentConferance={props.currentConferance}
                    conferanceButtons={props.conferanceButtons}
                    setCurrentConferance={props.setCurrentConferance}
                    setIsConferanceActive={props.setIsConferanceActive}
                    setCallId={props.setCallId}
                    callId={props.callId}
                    sidebarOpen={props.conferenceFormOpen}
                    statusInterval={props.statusInterval}
                    leaveConferance={props.leaveConferance}
                    selectedApp={props.selectedApp}
                    setSelectedApp={props.setSelectedApp}
                    insidePortalPanel={props.insidePortalPanel}
                    setVideoConferance={props.setVideoConferance}
                    setAdmitAll={props.setAdmitAll}
                    admitAll={props.admitAll}
                    callback={props.callback}
                     />
                {props.showModal &&
                    <Modal
                        show={props.showModal}
                        onHide={() => props.handleClose()}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <div className='modal-header-title'>
                            <Modal.Header>
                                <div>
                                    <FontAwesomeIcon icon={faCircleExclamation} style={{ color: ' #D13E2C', marginRight: '8px' }} />
                                    {t('Shared.error')}
                                </div>
                                <div className='custom-close-btn' onClick={() => props.handleClose()}>
                                    <FontAwesomeIcon icon={faXmark} />
                                </div>
                            </Modal.Header>
                        </div>
                        <Modal.Body>
                            {props.error}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => props.handleClose()}>
                                {t('Shared.ok')}
                            </Button>
                        </Modal.Footer>
                    </Modal>}
            </>
        )

    }

    return <></>

};