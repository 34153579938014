import React from 'react';
import { useEffect, useState, useRef } from "react";

import { GetRoomOS, PostRoomOS } from "../../roomos/Command";
import { startPoller, stopPoller, statusPoller, setPollerCallback, setPollerInterval } from '../../roomos/Poller';
import { ISapphirePortalComm } from '../../comm/WebSocket';
import { Offcanvas } from 'react-bootstrap';
import FormHeader from '../../common/FormHeader';
import FieldHeader from '../../common/FieldHeader';
import { useTranslation } from 'react-i18next';
import DropdownWidget from '../../common/DropdownWidget';
import CustomSwitch from '../../common/CustomSwitch';
import TextWidget from '../../common/TexWidget';
import PasswordWidget from '../../common/PasswordWidget';
import Button from '../../common/Button';
import './AddConferenceForm.scss';
import { conferenceOptions } from '../models';
interface IProps {
    setCurrentConferance: (currentConferance: Array<any>) => void;
    currentConferance: Array<any>;
    setSidebarOpen: (sidebarOpen: boolean) => void;
    setMinimize: (minimize: boolean) => void;
    setConferance: (conferance: boolean) => void;
    sapphirePortalComm: ISapphirePortalComm;
    roomosDevice: string,
    webexAuthToken: string,
    setError: (conferance: string) => void;
    handleShow: () => void;
    conferance: boolean;
    conferanceButtons: Array<any>;
    setIsConferanceActive: (isConferanceActive: boolean) => void;
    setCallId: (callId: number) => void;
    callId: number;
    meetingRoomID: string;
    sidebarOpen: boolean;
    statusInterval: any;
    leaveConferance: Function;
    // if user selects the app from first page
    selectedApp: number;
    setSelectedApp: Function;
    insidePortalPanel?: boolean;
    setVideoConferance?: Function;
    setAdmitAll: Function;
    admitAll:boolean;
    callback?: Function;
}

export default function AddConferenceForm(props: IProps) {

    const { t } = useTranslation();
    //polling
    const answerStatePoll = useRef(null);

    const [pollingStatus, setPollingStatus] = useState(null);
    const callStatusInterval = useRef<NodeJS.Timeout | null>(null);
    const [meetingLink, setMeetingLink] = useState("");
    const [meetingPasscode, setMeetingPasscode] = useState("");
    const [selectedApp, setSelectedApp] = useState<number>(0);
    const [createWebexMeeting, setCreateWebexMeeting] = useState<boolean>(false);

    const extractZoomMeetingId = (link) => {
        // This regex handles different Zoom URL structures
        const zoomRegex = /zoom\.us\/(?:j\/|wc(?:\/[^/]+)?\/join\/|wc\/)(\d+)/;
        const match = link.match(zoomRegex);

        // If there's a match and it has the expected capturing group, return the ID
        if (match && match[1]) {
            return match[1];
        }

        return null; // Return null or another default if there's no match
    };


    // when link is added in the form
    const handleMeetingLinkChange = (event) => {
        setMeetingLink(event.target.value);
    };

    // when app is selected in the form
    const handleAppChange = (selectedOption) => {
        setCreateWebexMeeting(false);
        setSelectedApp(selectedOption.value);
        props.setSelectedApp(selectedOption.value);
    };

    // when create new meeting for webex is selected
    const handleWebexCreateMeeting = (checked) => {
        setCreateWebexMeeting(checked);
    };

    const handleAdmitAll = (checked) => {
        props.setAdmitAll(checked);
    }

    // when passcode is changed
    const handlePasscodeChange = (event) => {
        if (typeof event.target.value === "undefined") {
            setMeetingPasscode('');
        }
        else {
            setMeetingPasscode(event.target.value);
        }
    };

    function isEmptyObject(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }
    // to connect the user to the conference
    const handleSave = async () => {
        //closes the form for add conferance
        props.setSidebarOpen(false);
        //initial page state change that changes the page into the portal
        if (!props.insidePortalPanel) {
            typeof props.callback === 'function' && props.callback();
        }
        const isConfernaceInitiated = await initiateConference();
        let callId = await getCallID();
        await setPollerCallback(() => callAnswerState(callId));
        await setPollerInterval(2000); // Poll every 2000ms (2 seconds)
        await startPoller();
        


        props.statusInterval.current = setInterval(() => {
            const latestStatus = statusPoller();
            setPollingStatus(latestStatus.result);
            if (isEmptyObject(latestStatus.result)) {
                stopPoller();
                props.leaveConferance();
                //in case of an ending from the roomos side close the conferance from all the clients
                props.setCurrentConferance([0, '', false, '']);
                clearInterval(props.statusInterval.current);
            }
        }, 2000);

        console.log("isConferanceInititated: ", isConfernaceInitiated);
        if (isConfernaceInitiated) {
            props.setConferance(true);
            props.setMinimize(false);
            props.setSidebarOpen(false);
            props.sapphirePortalComm.broadcastButton([true, true]);
            props.setIsConferanceActive(true);
        }

        await delay(5000);

        //if webex or zoom, start the presentation. Because Gmeet and MSteams does not have the functionality to present HDMI
        if (props.currentConferance[0] === 1 || props.currentConferance[0] === 4) {
            await PostRoomOS(props.roomosDevice,
                "Presentation.Start",
                {
                    "Instance": "3",
                    "Layout": "Prominent",
                    "PresentationSource": ["3"],
                    "SendingMode": "LocalRemote"
                },
                props.meetingRoomID + props.sapphirePortalComm.webexUniqueID);
        }
        if(props.currentConferance[2] === true){
            let meetingLinkUponCreation = await GetRoomOS(props.roomosDevice, `Conference.Call[${callId}].Webex.MeetingInviteLink`, props.meetingRoomID + props.sapphirePortalComm.webexUniqueID);
            let newConferance = [...props.currentConferance];
            newConferance[1] = meetingLinkUponCreation.result.Conference.Call[0].Webex.MeetingInviteLink;
            props.setCurrentConferance(newConferance);
        }
    };


    // when form for joining the meeting is closed
    const handleCancel = () => {
        setMeetingLink("");
        setMeetingPasscode("");
        setSelectedApp(0);
        props.setSelectedApp(-1);
        props.setSidebarOpen(false);
    };


    // this is called from the save function
    const initiateConference = async () => {
        try {
            let result; // Used to capture the return value of startConference

            switch (props.currentConferance[0]) {
                case 1:
                    let isZoomMeetingIdValid = extractZoomMeetingId(props.currentConferance[1]);
                    if (isZoomMeetingIdValid === null) {
                        throw new Error('Non valid Zoom Meeting Link.');
                    }
                    result = await startConference("Zoom.Join", {
                        "MeetingID": isZoomMeetingIdValid, // Reuse the valid ID we already extracted
                        // "MeetingPasscode": props.currentConferance[3],
                        "TrackingData": "Userful Spaces Meeting"
                    });
                    if (!result) {
                        throw new Error('Error starting the Zoom meeting.');
                    }
                    props.setCallId(result.CallId);
                    break;

                case 2:
                    // Assuming the same structure for the 'startConference' call
                    if (props.currentConferance[3] !== '') {
                        result = await startConference("WebRTC.Join", {
                            "Passcode": props.currentConferance[3],
                            "Title": "Spaces Meeting",
                            "TrackingData": "Userful Spaces Meeting",
                            "Type": "GoogleMeet",
                            "Url": props.currentConferance[1]
                        });
                    }
                    else {
                        result = await startConference("WebRTC.Join", {
                            "Title": "Spaces Meeting",
                            "TrackingData": "Userful Spaces Meeting",
                            "Type": "GoogleMeet",
                            "Url": props.currentConferance[1]
                        });
                    }

                    if (!result) {
                        throw new Error('Error joining the Google Meet meeting.');
                    }
                    break;

                case 3:
                    // And similarly here...
                    result = await startConference("WebRTC.Join", {
                        "Passcode": props.currentConferance[3],
                        "Title": "Spaces Meeting",
                        "TrackingData": "Userful Spaces Meeting",
                        "Type": "MSTeams",
                        "Url": props.currentConferance[1]
                    });
                    if (!result) {
                        throw new Error('Error joining the MS Teams meeting.');
                    }
                    break;

                case 4:
                    if (props.currentConferance[3] === '' && props.currentConferance[2] === false) {
                        result = await startConference("Webex.Join", {
                            "DisplayName": "Spaces Meeting",
                            "Number": props.currentConferance[1], // Assuming this should be '1' as in other 'Number' parameters
                            "ParticipantRole": "Guest",
                            "TrackingData": "Userful Spaces Meeting"
                        });
                        props.setCallId(result.CallId);
                    } else if (props.currentConferance[3] !== '' && props.currentConferance[2] === false) {
                        result = await startConference("Webex.Join", {
                            "DisplayName": "Spaces Meeting",
                            "Number": props.currentConferance[1],
                            "ParticipantRole": "Guest",
                            "Pin": props.currentConferance[3],
                            "TrackingData": "Userful Spaces Meeting"
                        });
                    } else if (props.currentConferance[2] === true) {
                        result = await startConference("Webex.Meetings.InstantMeeting.Start", {});
                    } else {
                        throw new Error("Invalid conditions for Webex meeting.");
                    }

                    if (!result) {
                        throw new Error('Error setting up the Webex meeting.');
                    }
                    break;

                default:
                    throw new Error('No valid conference type was provided.');
            }
            return true; // If we reached here, it means the operation was successful.
        } catch (error) {
            console.error(error); // Log the error for debugging purposes.
            return false; // Return false since an error occurred.
        }
    };

    // called inside the save funtion
    const startConference = async (meetingCommand, argument) => {
        await PostRoomOS(props.roomosDevice,
            "Call.Disconnect",
            {},
            props.meetingRoomID + props.sapphirePortalComm.webexUniqueID);
        try {
            await PostRoomOS(props.roomosDevice, meetingCommand, argument, props.meetingRoomID + props.sapphirePortalComm.webexUniqueID);
            return true; // The command was successful.
        } catch (error) {
            props.setError(`An error occurred while trying to start the conference. ${error.message}`);
            props.handleShow();  // Show the modal with the error message.\
            return false; // The command failed.
        }
    };


    // called from the save function
    var getCallID = async () => {
        try {
            const varifyExistanceOfMacro = await PostRoomOS(props.roomosDevice,
                "Macros.Macro.Get",
                {
                    "Content": "True",
                    "Name": "UserfulCallId"
                },
                props.meetingRoomID + props.sapphirePortalComm.webexUniqueID);
            const doesMacroExist = varifyExistanceOfMacro.result.Macro[0].Content === "const xapi = require('xapi'); xapi.Status.Call.get().then(device => { console.log(device)});"
            if (!doesMacroExist) {
                await PostRoomOS(props.roomosDevice,
                    "Macros.Macro.Save",
                    {
                        "Name": "UserfulCallId",
                        "Overwrite": "True",
                        "Transpile": "True"
                    },
                    props.meetingRoomID + props.sapphirePortalComm.webexUniqueID,
                    undefined,
                    "const xapi = require('xapi'); xapi.Status.Call.get().then(device => { console.log(device)});");
            }
        } catch {
            await PostRoomOS(props.roomosDevice,
                "Macros.Macro.Save",
                {
                    "Name": "UserfulCallId",
                    "Overwrite": "True",
                    "Transpile": "True"
                },
                props.meetingRoomID + props.sapphirePortalComm.webexUniqueID,
                undefined,
                "const xapi = require('xapi'); xapi.Status.Call.get().then(device => { console.log(device)});");
        }
        await PostRoomOS(props.roomosDevice,
            "Macros.Log.Clear",
            {},
            props.meetingRoomID + props.sapphirePortalComm.webexUniqueID);
        await PostRoomOS(props.roomosDevice,
            "Macros.Macro.Activate",
            { "Name": "UserfulCallId" },
            props.meetingRoomID + props.sapphirePortalComm.webexUniqueID);
        await PostRoomOS(props.roomosDevice,
            "Macros.Runtime.Restart",
            {},
            props.meetingRoomID + props.sapphirePortalComm.webexUniqueID);
        await delay(800);
        var getMacroLog = await PostRoomOS(props.roomosDevice,
            "Macros.Log.Get",
            { "Offset": 0 },
            props.meetingRoomID + props.sapphirePortalComm.webexUniqueID);
        const callIdLog = getMacroLog.result.Line.filter(entry =>
            entry.Macro === "UserfulCallId" && entry.Level === "LOG"
        ).pop();
        

        try {
            let callIdJSON = senatizeJSON(callIdLog.Message);
            props.setCallId(callIdJSON.id);
            return callIdJSON.id;
        } catch (e) {
            console.error("JSON parsing error: ", e.message);
            return 0;
        }

        
        
    };

    // called insdie the save function
    function delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const senatizeJSON = (callIdLogString:string) => {
        let callIdLog;
        const expectedProperties = ["AnswerState", "CallType", "CallbackNumber", "Direction", "DisplayName", "Duration", "Protocol", "RemoteNumber", "Status", "id"];
        
        //prune known cases
        callIdLog = callIdLogString.replace(/'/g, '"');
        callIdLog = callIdLog.replace(/^\[\s*|\s*\]$/g, '');
        callIdLog = callIdLog.replace(/([a-zA-Z0-9_]+):/g, '"$1":');
        callIdLog = callIdLog.replace(/"spark":/g, '\\"spark\\":');
        callIdLog = callIdLog.replace(/,\s*([}\]])/g, '$1'); // Remove trailing commas before closing braces or brackets
        callIdLog = callIdLog.replace(/""https"/g, '"https');
        callIdLog = callIdLog.replace(/(CallbackNumber|RemoteNumber":\s*")([^"]*)"/g, '$1$2\\"');
        callIdLog = callIdLog.replace(/\\/g, "\\\\").replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t").replace(/\f/g, "\\f").replace(/"/g,"\\\"").replace(/'/g,"\\\'").replace(/\&/g, "\\&"); 
        
        //string senatization
        try {
            return JSON.parse(callIdLog);
        } catch {
            return safeJSONParse(callIdLogString, expectedProperties, 1000);
        }
         
    } 

    function safeJSONParse(str, propArray, maxLen) {
        let parsedObj, safeObj = {};
        try {
            if (maxLen && str.length > maxLen) {
                return null;
            } else {
                parsedObj = JSON.parse(str);
                if (typeof parsedObj !== "object" || Array.isArray(parsedObj)) {
                    safeObj = parsedObj;
                } else {
                    // copy only expected properties to the safeObj
                    propArray.forEach(function(prop) {
                        if (parsedObj.hasOwnProperty(prop)) {
                            safeObj[prop] = parsedObj[prop];
                        }
                    });
                }
                return safeObj;
            }
        } catch(e) {
            return null;
        }
    }

    // called inside the save function
    let callAnswerState = async (callId) => {
        const status = await GetRoomOS(props.roomosDevice, `Call[${callId}].AnswerState`, props.meetingRoomID + props.sapphirePortalComm.webexUniqueID);
        if (isEmptyObject(status)) {
            // If status is an empty object, stop polling
            answerStatePoll.current.stop();
            props.leaveConferance();
        }
        return status;
    };

    useEffect(() => {
        props.setCurrentConferance([selectedApp, meetingLink, createWebexMeeting, meetingPasscode]);
    }, [selectedApp, meetingLink, createWebexMeeting, meetingPasscode]);

    useEffect(() => {
        return () => {
            // Clean up polling
            if (answerStatePoll.current) {
                answerStatePoll.current.stop();
            }
            // Clear interval if it's set
            if (callStatusInterval.current) {
                clearInterval(callStatusInterval.current);
            }
        };
    }, []);

    useEffect(() => {

        if (props.selectedApp !== -1) {
            setSelectedApp(props.selectedApp);
        }
    }, [props.selectedApp]);



    return (

        <Offcanvas show={props.sidebarOpen} onHide={handleCancel} placement="end" className="createUserForm">
            <Offcanvas.Body>
                <div className="overallUserForm">
                    <FormHeader handleClose={() => handleCancel()} name={t('PortalApp.AddConferenceHead')} description={t('PortalApp.AddConferenceDes')} />
                    <div className="userDetailsDiv">
                        <FieldHeader name={t('PortalApp.AddConferenceSubHead')} description={t('PortalApp.AddConferenceSubDes')} />
                        <DropdownWidget
                            options={conferenceOptions}
                            selectedValue={conferenceOptions.find(option => option.value === selectedApp)}
                            width={528}
                            title={t('PortalApp.App')}
                            onChange={handleAppChange}
                        />
                        {selectedApp === 4 && <CustomSwitch
                            name={t('PortalApp.CreateMeeting')}
                            description={t('PortalApp.CreateMeetingDes')}
                            check={createWebexMeeting}
                            onChange={handleWebexCreateMeeting}
                        />}
                        {!createWebexMeeting &&
                            <TextWidget
                                title={t('PortalApp.MeetingLink')}
                                displayLength={false}
                                placeholder={t('PortalApp.MeetingLinkPlaceholder')}
                                keys="meetingLink"
                                formValue={meetingLink}
                                invalidState={false}
                                invalidText={t('UserManagement.LDAP.Error.batchSizeForSyncError')}
                                width={528}
                                id="meetingLink"
                                onChange={handleMeetingLinkChange}
                                onBlur={handleMeetingLinkChange}
                                showHelpInfo={false}
                            />
                        }
                        {!createWebexMeeting && <PasswordWidget
                            title={t('PortalApp.Password')}
                            displayCheck={false}
                            keys="password"
                            formValue={meetingPasscode}
                            invalidState={false}
                            invalidText={t('UserManagement.passwordError')}
                            width={528}
                            onChange={handlePasscodeChange}
                            id="user-password-input"
                            onBlur={handlePasscodeChange} />}
                    </div>
                    {/* <CustomSwitch
                        name={t('PortalApp.AdmitAllGuests')}
                        description={t('PortalApp.AdmitAllGuestsDes')}
                        check={props.admitAll}
                        onChange={handleAdmitAll}
                    /> */}
                </div>
                <div className="button-wrapper">
                    <Button variant='primary'
                        disabled={false}
                        onClick={handleSave}
                        id="save-source-button"
                    >
                        {t('PortalApp.Connect')}
                    </Button>
                    <Button variant='secondary' onClick={handleCancel}>
                        {t('PortalApp.Cancel')}
                    </Button>
                </div>
            </Offcanvas.Body>
        </Offcanvas >

    );
}
