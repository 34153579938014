import React, { useEffect } from 'react';

interface IProps {
    url: string;
    setCurrentSources: (currentSources: Array<any>) => void;
    globalStateLink: string;
}

export default function SessionKeeper(props: IProps) {
    useEffect(() => {
        // Open a new window for authentication
        const authWindow = window.open(props.url, "Auth Window", "width=600,height=400");

        // Function to check if the authentication window is closed
        const checkAuthWindowClosed = setInterval(() => {
            if (authWindow && authWindow.closed) {
                clearInterval(checkAuthWindowClosed);
                // createIframe(); // Create the iframe after authentication
            }
        }, 1000);

        // Function to create an invisible iframe to maintain the session
        // const createIframe = () => {
        //     const iframe = document.createElement('iframe');
        //     iframe.style.display = 'none';
        //     iframe.src = props.url; // URL of the authenticated session
        //     document.body.appendChild(iframe);
        // };

        return () => {
            clearInterval(checkAuthWindowClosed);
        };
    }, [props.url]);

    useEffect(() => {
        // Function to handle incoming messages
        const handleMessage = (event: MessageEvent) => {
            // Check the origin of the message for security
            if (event.origin === props.globalStateLink || event.origin === "http://localhost:3000" || event.origin === "http://localhost:3001") {
                // Handle the message
                // Handle the message
                console.log("data returned: ", event.data);
                if (event.data.length > 0) {
                    props.setCurrentSources(event.data);
                }
                return;
            }
            if (event.data.lenght === 0) {
                return;
            }

        };

        // Add event listener for receiving messages
        window.addEventListener('message', handleMessage);

        // Cleanup
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return null;
};
