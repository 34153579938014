import { useEffect, useState, useRef } from 'react';
import { MemoryRouter as Router, Routes, Route } from 'react-router-dom';

import JoinMeetingSpace from './components/JoinMeetingSpace';
import useSapphirePortalComm, { ISapphirePortalComm } from './comm/WebSocket';
import PortalPanel from './components/PortalPanel';
import useWebRTCCaptureMrg from './webrtc/Messaging';
import UserfulPageLayout from './layout/UserfulPageLayout';
import { LayoutItem, ConnectionMapping } from './components/ScreenSharePanel';
import { library } from '@fortawesome/fontawesome-svg-core';

import "./App.scss"
import 'bootstrap/dist/css/bootstrap.css';
import './main.scss';
import './UserfulColors.css';

import {
  faDisplay, faArrowRight, faWrench, faCircleNotch,
  faUsersCog, faCube, faSlidersH, faCloud, faDatabase, faEllipsisV, faServer, faHdd, faAngleDown, faAngleRight,
  faUndoAlt, faUserCircle, faSort, faLink, faEdit, faTrash, faCog, faMapMarkerAlt, faUsers, faChalkboardUser, faDoorOpen,
} from '@fortawesome/free-solid-svg-icons';
import { url } from 'inspector';

library.add(faDisplay, faArrowRight, faWrench, faUsersCog, faCube, faSlidersH, faCloud, faDatabase, faEllipsisV, faServer, faHdd, faDoorOpen,
  faAngleDown, faAngleRight, faUndoAlt, faUserCircle, faSort, faLink, faEdit, faTrash, faCircleNotch, faMapMarkerAlt, faCog, faUsers, faChalkboardUser);



interface IProps {
}

export default function App(props: IProps) {
  const [pairingCode, setPairingCode] = useState("");
  const [meetingRoomID, setMeetingRoomID] = useState("");
  const [guestName, setGuestName] = useState("Guest");
  const [layout, setLayout] = useState<LayoutItem[]>([]);
  const [localLayout, setLocalLayout] = useState<LayoutItem[]>([]);
  const [connectionMapping, setConnectionMapping] = useState<ConnectionMapping[]>([]);
  const [currentGuests, setCurrentGuests] = useState<object>({});
  const [roomOSDevices, setRoomOSDevices] = useState([]);
  const [selectedRoomOSDevice, setSelectedRoomOSDevice] = useState<object>({});
  const [roomosDevice, setRoomosDevice] = useState<string>('');
  const [webexAuthToken, setWebexAuthToken] = useState<string>('');
  const [admitAll, setAdmitAll] = useState<boolean>(false);

  // this state is to show the video conferencing page
  const [videoConferencePage, setVideoConferencePage] = useState(false);

  // this is for casting your screen
  const [uCast, setUCast] = useState(false);
  // this is if the conference type is selected or not
  const [conferenceType, setConferenceType] = useState(-1);

  const [globalStateLink, setGlobalStateLink] = useState<string>('');
  const [inMeeting, setMeeting] = useState<boolean>(false);
  const [conferanceButtons, setConferanceButtons] = useState<Array<any>>([false, true]);
  const [buttonBroadcastRecievedFromOtherClients, setButtonBroadcastRecievedFromOtherClients] = useState<Array<any>>([]);
  // index 0 = conferance
  // index 1 = minimize

  const sapphirePortalComm: ISapphirePortalComm = useSapphirePortalComm(setButtonBroadcastRecievedFromOtherClients, setRoomosDevice, setGlobalStateLink, setWebexAuthToken, setMeetingRoomID, setConnectionMapping, layout, currentGuests, setCurrentGuests, setLayout, {

    onOpen: () => {
      sapphirePortalComm.registerClient();
    }
  }
  );

  useEffect(() => {

    var urlParams = new URLSearchParams(window.location.search);
    if (window.location.pathname === "/auth") {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('code');
      if (token && window.opener) {
        window.opener.postMessage({ authCode: token });
        window.close()
      }
    }
    if(window.opener){
      let referrer = document.referrer;
      let url = new URL(referrer);
      let domain = url.hostname; // This gets the domain part of the URL
      if (window.opener && domain === "idbroker-ca.webex.com" && urlParams.size ===2 ){
        const getGlobalHost = urlParams.get("state");
        const paramString = urlParams.toString();
        if (getGlobalHost){
          window.location.replace(getGlobalHost + "?" + paramString);
        }
        
      }
    }
  });

  const { startCasting, endCasting, isCasting, muteSound, isMuted } = useWebRTCCaptureMrg(webexAuthToken, roomosDevice, pairingCode, guestName.length >= 10 ? guestName.substring(0, 10) + "..." : guestName, sapphirePortalComm);

  return (
    <div className="App">
      
      <UserfulPageLayout className="main-container" showHelp showWindowMax >
        <Router>
          <Routes>
            <Route path="/" element={<JoinMeetingSpace
              inMeeting={inMeeting}
              pairingCode={pairingCode}
              setPairingCode={setPairingCode}
              guestName={guestName}
              setGuestName={setGuestName}
              isMeetingRoomAvailable={sapphirePortalComm.isMeetingRoomAvailable}
              isMeetingRoomOccupied={sapphirePortalComm.isMeetingRoomOccupied}
              setMeetingStatus={sapphirePortalComm.setMeetingStatus}
              startCasting={startCasting}
              isCasting={isCasting}
              setMeeting={setMeeting}
              setVideoConferencePage={(bool: boolean) => { setVideoConferencePage(bool) }} />
            } />




            <Route path="/meeting" element={
              <PortalPanel
                // isMuted={isMuted}
                buttonBroadcastRecievedFromOtherClients={buttonBroadcastRecievedFromOtherClients}
                globalStateLink={globalStateLink}
                inMeeting={inMeeting}
                setMeeting={setMeeting}
                startCasting={startCasting}
                sapphirePortalComm={sapphirePortalComm}
                conferanceButtons={conferanceButtons}
                roomosDevice={roomosDevice}
                webexAuthToken={webexAuthToken}
                roomOSDevices={roomOSDevices}
                setSelectedRoomOSDevice={setSelectedRoomOSDevice}
                pairingCode={pairingCode}
                meetingRoomID={meetingRoomID}
                layoutType={sapphirePortalComm.layoutType}
                setLayoutType={sapphirePortalComm.setLayoutType}
                sendLayoutToTheSource={sapphirePortalComm.sendLayoutToTheSource}
                sendHLSLink={sapphirePortalComm.sendHLSLink}
                connectionMapping={connectionMapping}
                currentGuests={currentGuests}
                guestName={guestName}
                endCasting={endCasting}
                isCasting={isCasting}
                layout={layout}
                setLayout={setLayout}
                setLocalLayout={setLocalLayout}
                muteSound={muteSound}
                setLayoutData={sapphirePortalComm.setLayoutData}
                setLayoutTypeAndSendAll={sapphirePortalComm.setLayoutTypeAndSendAll}
                setLayoutAndSendToAllUponChange={sapphirePortalComm.setLayoutAndSendToAllUponChange}
                localLayout={localLayout}
                videoConferencePage={videoConferencePage}
                setVideoConference={(bool: boolean) => { setVideoConferencePage(bool) }}
                setGuestName={setGuestName}
                uCast={uCast}
                setUCast={(bool) => setUCast(bool)}
                conferenceType={conferenceType}
                setConferenceType={setConferenceType}
                setSelectedApp={(value)=> setConferenceType(value)} 
                setAdmitAll={(value)=> setAdmitAll(value)}
                admitAll={admitAll}/>
              
            } />
          </Routes>
        </Router>
      </UserfulPageLayout>
    </div>
  );

}
