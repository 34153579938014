import React from 'react';
import './css/ControlBar.scss'; // Importing styles for our bar
import { GetRoomOS } from '../roomos/Command';
import { PostRoomOS } from "../roomos/Command";
import {
    faMicrophone, faMicrophoneSlash, faVideo, faVolume, faVolumeSlash, faTableLayout, faUsers, faMultiply, faVideoSlash,
    faUser, faPresentationScreen, faCompressWide, faExpand
} from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from "react";
import './ControlBar.scss';
import { useTranslation } from 'react-i18next';
import ActionButton from './ActionButton';
import { ISapphirePortalComm } from '../comm/WebSocket';
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import Button from '../common/Button';
import ParticipantsForm from './forms/ParticipantsForm';
import { startPoller as startPollerAdmitAll, stopPoller as stopPollerAdmitAll, statusPoller as statusPollerAdmitAll, setPollerCallback as setPollerCallbackAdmitAll, setPollerInterval as setPollerIntervalAdmitAll} from './AdmitAllPoller';

interface IProps {
    meetingRoomID: string;
    roomosDevice: string;
    webexAuthToken: string;
    callId: number;
    currentConferance: Array<any>;
    setCurrentConferance: (currentConferance: Array<any>) => void;
    conferanceButtons: Array<any>;
    sapphirePortalComm: ISapphirePortalComm;
    leaveConferanceEvent: Function;
    admitAll:boolean;
}

export default function ConferenceActions(props: IProps) {
    //mute unmute micraphone
    //self-view no self view
    // mute sound
    //change grid

    const { t } = useTranslation();

    const pipPositions = {
        0: "CenterLeft",
        1: "CenterRight",
        2: "LowerLeft",
        3: "LowerRight",
        4: "UpperCenter",
        5: "UpperLeft",
        6: "UpperRight"
    };

    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [isVolumeMuted, setIsVolumeMuted] = useState<boolean>(false);
    const [isSelfView, setIsSelfView] = useState<number>(0);
    const [isCameraOff, setIsCameraOff] = useState<boolean>(false);
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [participants, setParticipants] = useState<Array<any>>([]);
    const [conferance, setConferance] = useState<boolean>(props.conferanceButtons[0]);
    const [minimize, setMinimize] = useState<boolean>(props.conferanceButtons[1]);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [leaveConferenceModal, setLeaveConferenceModal] = useState(false);
    const [broadcastButton, setBroadcastButton] = useState<Array<any>>([]);


    const meetTypes = ['Zoom', 'GooglMeet', 'MicrosoftTeams', 'WebexMeet'];

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    function delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleIncrement = () => {
        setIsSelfView(prevValue => {
            if (prevValue === 6) {
                return 0;
            } else {
                return prevValue + 1;
            }
        });
    };

    const layoutChange = () => {
        setIsSelfView(prevValue => {
            if (prevValue === 6) {
                return 0;
            } else {
                return prevValue + 1;
            }
        });
    };

    const muteConferance = () => {
        if (isMuted === true) {
            makeCallToRoomOS("POST", "Audio.Microphones.Unmute", {});
            setIsMuted(false);
        }
        else {
            makeCallToRoomOS("POST", "Audio.Microphones.Mute", {});
            setIsMuted(true);
        }
    };

    const muteVolume = () => {
        if (isVolumeMuted === true) {
            makeCallToRoomOS("POST", "Audio.Volume.Mute", {});
            setIsVolumeMuted(false);
        }
        else {
            makeCallToRoomOS("POST", "Audio.Volume.Unmute", {});
            setIsVolumeMuted(true);
        }
    };


    const setSelfViewLocation = () => {
        setIsCameraOff(true);
        switch (isSelfView) {
            case 0:
                makeCallToRoomOS("POST", "Video.Selfview.Set", {
                    "FullscreenMode": "Off",
                    "Mode": "On",
                    "OnMonitorRole": "First",
                    "PIPPosition": "CenterLeft"
                });
                handleIncrement();
                break;
            case 1:
                makeCallToRoomOS("POST", "Video.Selfview.Set", {
                    "FullscreenMode": "Off",
                    "Mode": "On",
                    "OnMonitorRole": "First",
                    "PIPPosition": "CenterRight"
                });
                handleIncrement();
                break;

            case 2:
                makeCallToRoomOS("POST", "Video.Selfview.Set", {
                    "FullscreenMode": "Off",
                    "Mode": "On",
                    "OnMonitorRole": "First",
                    "PIPPosition": "LowerLeft"
                });
                handleIncrement();
                break;

            case 3:
                makeCallToRoomOS("POST", "Video.Selfview.Set", {
                    "FullscreenMode": "Off",
                    "Mode": "On",
                    "OnMonitorRole": "First",
                    "PIPPosition": "LowerRight"
                });
                handleIncrement();
                break;

            case 4:
                makeCallToRoomOS("POST", "Video.Selfview.Set", {
                    "FullscreenMode": "Off",
                    "Mode": "On",
                    "OnMonitorRole": "First",
                    "PIPPosition": "UpperCenter"
                });
                handleIncrement();
                break;

            case 5:
                makeCallToRoomOS("POST", "Video.Selfview.Set", {
                    "FullscreenMode": "Off",
                    "Mode": "On",
                    "OnMonitorRole": "First",
                    "PIPPosition": "UpperLeft"
                });
                handleIncrement();
                break;

            case 6:
                makeCallToRoomOS("POST", "Video.Selfview.Set", {
                    "FullscreenMode": "Off",
                    "Mode": "On",
                    "OnMonitorRole": "First",
                    "PIPPosition": "UpperRight"
                });
                handleIncrement();
                break;

            default:
                throw new Error('No valid conference type was provided.');
        }
    };

    const layoutChangeButton = () => {
        switch (isSelfView) {
            case 0:
                makeCallToRoomOS("POST", "Video.Layout.LayoutFamily.Set", {
                    "LayoutFamily": "auto",
                    "Target": "Local"
                });
                layoutChange();
                break;
            case 1:
                makeCallToRoomOS("POST", "Video.Layout.LayoutFamily.Set", {
                    "LayoutFamily": "custom",
                    "Target": "Local"
                });
                layoutChange();
                break;

            case 2:
                makeCallToRoomOS("POST", "Video.Layout.LayoutFamily.Set", {
                    "LayoutFamily": "equal",
                    "Target": "Local"
                });
                layoutChange();
                break;

            case 3:
                makeCallToRoomOS("POST", "Video.Layout.LayoutFamily.Set", {
                    "LayoutFamily": "overlay",
                    "Target": "Local"
                });
                layoutChange();
                break;

            case 4:
                makeCallToRoomOS("POST", "Video.Layout.LayoutFamily.Set", {
                    "LayoutFamily": "prominent",
                    "Target": "Local"
                });
                layoutChange();
                break;

            case 5:
                makeCallToRoomOS("POST", "Video.Layout.LayoutFamily.Set", {
                    "LayoutFamily": "prominent_l",
                    "Target": "Local"
                });
                layoutChange();
                break;

            case 6:
                makeCallToRoomOS("POST", "Video.Layout.LayoutFamily.Set", {
                    "LayoutFamily": "single",
                    "Target": "Local"
                });
                layoutChange();
                break;

            default:
                throw new Error('No valid conference type was provided.');
        }
    };

    const setCameraMode = () => {
        if (isCameraOff === false) {
            makeCallToRoomOS("POST", "Video.Selfview.Set", {
                "Mode": "On"
            });
            setIsCameraOff(true);
        }
        else {
            makeCallToRoomOS("POST", "Video.Selfview.Set", {
                "Mode": "Off"
            });
            setIsCameraOff(false);
        }
    };

    const copyMeetingLinkToClipboard = () => {
        //TODO: I guess
    };

    const listParticipants = async () => {
        setSidebarOpen(true);
        //TODO: I guess
        const response = await makeCallToRoomOS("POST", "Conference.ParticipantList.Search", {
            "CallId": Number(props.callId)
        });
        if (response !== false) {
            setParticipants(response.result.Participant)
            setSidebarOpen(true);
        }
    };


    const makeCallToRoomOS = async (method, meetingCommand, argument?) => {
        try {
            if (method === "POST") {
                const response = await PostRoomOS(props.roomosDevice, meetingCommand, argument, props.meetingRoomID + props.sapphirePortalComm.webexUniqueID);
                return response;
            }
            else if (method === "GET") {
                const response = await GetRoomOS(props.roomosDevice, meetingCommand, props.meetingRoomID + props.sapphirePortalComm.webexUniqueID);
                return response;
            }
            else {
                throw new Error("Method is not correct. Either POST or GET.");
            }
            // The command was successful.
        } catch (error) {
            return false; // The command failed.
        }
    };

    const presentationStart = async () => {
        await PostRoomOS(props.roomosDevice,
            "Presentation.Start",
            {
                "Instance": "3",
                "Layout": "Prominent",
                "PresentationSource": ["3"],
                "SendingMode": "LocalRemote"
            },
            props.meetingRoomID + props.sapphirePortalComm.webexUniqueID);
    };

    const minimizeSpace = async () => {
        await PostRoomOS(props.roomosDevice,
            "Video.PresentationView.Set",
            { "View": "Minimized" },
            props.meetingRoomID + props.sapphirePortalComm.webexUniqueID)
        setConferance(true);
        setMinimize(false);
    };

    const enlargeSpace = async () => {
        await PostRoomOS(props.roomosDevice,
            "Video.PresentationView.Set",
            { "View": "Default" },
            props.meetingRoomID + props.sapphirePortalComm.webexUniqueID)
        setConferance(true);
        setMinimize(true);
    };

    const handleCloseConferenceModal = () => {
        setLeaveConferenceModal(false);
    }

    const openEndDialog = () => {
        setLeaveConferenceModal(true);
    }
    const handleEnd = () => {
        props.leaveConferanceEvent();
        //close the conferance from all clients
        props.setCurrentConferance([0, '', false, '']);
        setLeaveConferenceModal(false);
    }

    useEffect(() => {
        setBroadcastButton([...props.currentConferance, isMuted, isCameraOff, isVolumeMuted, minimize, isSelfView]);
        props.sapphirePortalComm.broadcastButton([...props.currentConferance, isMuted, isCameraOff, isVolumeMuted, minimize, isSelfView]);
        //broadcastButton is what we recieve
        //currentConferance is the array of buttons we currently have
    }, [props.currentConferance, isMuted, isCameraOff, isVolumeMuted, minimize, isSelfView])


    useEffect(()=>{
        if (props.admitAll === true && props.callId !== -1) {
            
            setPollerCallbackAdmitAll(async ()=>{
                await delay(5000);
                await makeCallToRoomOS("POST", "Conference.AdmitAll", {
                    "CallId": Number(props.callId)
                });
            });
            setPollerIntervalAdmitAll(2000); // Poll every 2000ms (2 seconds)
            startPollerAdmitAll();
        }

        return () => {
            stopPollerAdmitAll();
        }
        //https://webex-api.userful.dev/webexp/command/dsadasfsfa/Conference.AdmitAll

    }, [props.callId]);

    return (
        <>
            <div className='controlBar'>
                <div className='conrolBarTop'>
                    <div className='controlBarConferenceText'>
                        {t(`PortalApp.Conference`)}
                    </div>
                    <div className='controlBarType' onClick={openEndDialog}>
                        {props.currentConferance[0] === 1 && <img src="./assets/zoom.svg" alt="Icon" />}
                        {props.currentConferance[0] === 2 && <img src="./assets/meet.png" alt="Icon" />}
                        {props.currentConferance[0] === 3 && <img src="./assets/teams.png" alt="Icon" />}
                        {props.currentConferance[0] === 4 && <img src="./assets/webx.png" alt="Icon" />}
                        <div className="typeText">
                            {`${t(`PortalApp.${meetTypes[props.currentConferance[0] - 1]}`)} ${t('PortalApp.Connected')}`}
                        </div>
                        <div className="typeIconDiv">
                            <FontAwesomeIcon icon={faMultiply} className="typeIconCross" />
                        </div>

                    </div>
                </div>
                <div className="controlBarBottom">
                    <div className='controlBarConferenceText'>
                        {t('PortalApp.ConferenceActions')}
                    </div>
                    <div className="showActionButtons">
                        <ActionButton text={t('PortalApp.ConferenceLayout')} icon={faTableLayout} onClick={layoutChangeButton} />
                        <ActionButton text={t('PortalApp.OpenSelfView')} icon={faUser} onClick={setSelfViewLocation} />
                        <ActionButton text={t('PortalApp.PresentationStart')} icon={faPresentationScreen} onClick={presentationStart} />
                        {!isMuted && <ActionButton text={t('PortalApp.MuteMicrophone')} icon={faMicrophone} onClick={muteConferance} />}
                        {isMuted && <ActionButton text={t('PortalApp.UnmuteMicrophone')} icon={faMicrophoneSlash} onClick={muteConferance} />}
                        {isCameraOff && <ActionButton text={t('PortalApp.HideVideo')} icon={faVideo} onClick={setCameraMode} />}
                        {!isCameraOff && <ActionButton text={t('PortalApp.UnhideVideo')} icon={faVideoSlash} onClick={setCameraMode} />}
                        {isVolumeMuted && <ActionButton text={t('PortalApp.MuteVolume')} icon={faVolume} onClick={muteVolume} />}
                        {!isVolumeMuted && <ActionButton text={t('PortalApp.UnmuteVolume')} icon={faVolumeSlash} onClick={muteVolume} />}
                        {!minimize && <ActionButton text={t('PortalApp.Maximize')} icon={faExpand} onClick={enlargeSpace} />}
                        {minimize && <ActionButton text={t('PortalApp.Minimize')} icon={faCompressWide} onClick={minimizeSpace} />}
                        <ActionButton text={t('PortalApp.Participants')} icon={faUsers} onClick={listParticipants} />
                    </div>
                </div>
            </div>
            <ParticipantsForm
                roomosDevice={props.roomosDevice}
                webexAuthToken={props.webexAuthToken}
                setSidebarOpen={setSidebarOpen}
                participants={participants}
                currentConferance={props.currentConferance}
                setCurrentConferance={props.setCurrentConferance}
                sidebarOpen={sidebarOpen}
            />
            {showModal &&
                <Modal
                    show={showModal}
                    onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className='modal-header-title'>
                        <Modal.Header>
                            <div>
                                <FontAwesomeIcon icon={faCircleExclamation} style={{ color: ' #D13E2C', marginRight: '8px' }} />
                                {t('Shared.error')}
                            </div>
                            <div className='custom-close-btn' onClick={handleClose}>
                                <FontAwesomeIcon icon={faXmark} />
                            </div>
                        </Modal.Header>
                    </div>

                    <Modal.Body>
                        {error}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t('Shared.ok')}
                        </Button>
                    </Modal.Footer>
                </Modal>}
            <Modal show={leaveConferenceModal} onHide={handleCloseConferenceModal} aria-labelledby="contained-modal-title-vcenter" centered className="exitConference">
                <div className="modal-header-title" style={{ marginBottom: "0px" }}>
                    <Modal.Header>
                        <div>
                            <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: " FFA200", marginRight: "8px" }} />
                            {t('PortalApp.EndConference')}
                        </div>
                        <div className="modal-close-btn">
                            <FontAwesomeIcon icon={faXmark} onClick={handleCloseConferenceModal} className="custom-close-btn fontAwesomeCustomIcon" />
                        </div>
                    </Modal.Header>
                </div>

                <Modal.Body>
                    {t('PortalApp.EndConferenceDes')}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConferenceModal}>
                        {t('PortalApp.Cancel')}
                    </Button>
                    <Button variant="danger" onClick={handleEnd}>
                        {t('PortalApp.EndConference')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    );
}

