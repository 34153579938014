const URL_BASE_CLOUD = "https://webex-api.userful.net/" //fill with the EC2 instance
const OVERRIDE = false; //override or not, defaults to current API

function currentLink(link=""){ //method true post method false get
    if (OVERRIDE){
        return link;
    }
    const currentPageHost = window.location.hostname;
    const currentLinkHref = window.location.href;
    const pathname = window.location.pathname;
    if (currentPageHost === "localhost") {
        // This is in a dev enviornment. 
        return URL_BASE_CLOUD;
    }
    else if (pathname.startsWith("/meet")) {
        // This is air-gapped.
        return currentLinkHref;
    } else {
        // Cloud
        return URL_BASE_CLOUD;
    }
}

export async function GetRoomOS(device_id, command, meetingRoomName, access_token?){
    let response_data;
    // Your API endpoint
    const EXTENSION = `webexp/status/${meetingRoomName}/${device_id}/${command}`;
    const OVERRIDE_URL = `https://webexapis.com/v1/xapi/status/?deviceId=${device_id}&name=${command}`

    var url;
    var headers;

    if (OVERRIDE){
        url = OVERRIDE_URL;
    }
    else{
        url = currentLink() + EXTENSION;
    }
    if (OVERRIDE){
        headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + access_token,  // Replace with your actual token
        }
    }
    else{
        headers = {
            'Content-Type': 'application/json'
        }
    }

    // Make the GET request
    await fetch(url, {
        method: 'GET',      // Specify the method
        headers: headers
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }
        return response.json();
    })
    .then(data => {
        response_data = data;
    })
    .catch(error => {
        console.error('Fetch error:', error);  // Handle any errors
    });
    return response_data;
}

export async function PostRoomOS(device_id, command, argument, meetingRoomName, access_token?, body?){
    const EXTENSION =  `webexp/command/${meetingRoomName}/${command}`;

    const OVERRIDE_URL = `https://webexapis.com/v1/xapi/command/${command}`;

    var url;
    var headers;

    if (OVERRIDE){
        url = OVERRIDE_URL;
    }
    else{
        url = currentLink() + EXTENSION;
    }

    const data = {
        "deviceId": device_id,
        "arguments" : argument,
        "body": body
    };

    if (OVERRIDE){
        headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + access_token  // Replace with your actual token
        }
    }
    else{
        headers = {
            'Content-Type': 'application/json'
        }
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        });
        const responseData = await response.json(); // Parse the JSON of the response
        // Check if the request was successful
        if (!response.ok) {
            throw new Error(`${responseData.message}`);
        }
        return responseData; // You need to return here for the outside usage of the function
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrowing the error stops execution and could be caught where the function is called
    }
}